.article_form {

  overflow: hidden;
  margin-bottom: 50px;

  &__left {

    width: 100%;

    @media only screen and (min-width: $smallBreakpoint) {

      float: left;
      width: calc(50% - 10px);
      margin-right: 20px;
    }

    @media only screen and (min-width: $mediumBreakpoint) {

      width: calc(50% - 20px);
      margin-right: 40px;
    }
  }

  &__right {

    width: 100%;
    padding: 0 20px;

    @media only screen and (min-width: $smallBreakpoint) {

      float: left;
      width: calc(50% - 10px);
      padding: 0;
    }

    @media only screen and (min-width: $mediumBreakpoint) {

      width: calc(50% - 20px);
    }
  }
}
