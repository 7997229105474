.publicationsGrid {

  .article {

    overflow: hidden;
    margin-bottom: 20px;

    @media only screen and (min-width: $mediumBreakpoint) {

      margin-bottom: 30px;
    }

    &__image {

      position: relative;
      height: 156px;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      //background-size: cover;
      display: block;


      background-size: 100%;
      transition: background .3s ease-in-out;

      &:hover {
        background-size: 115%;
      }

      @media only screen and (min-width: $smallBreakpoint) {

        float: left;
        width: 225px;
        height: 136px;
      }

      @media only screen and (min-width: $mediumBreakpoint) {

        height: 156px;
      }

      &__plus {

        position: absolute;
        right: 10px;
        bottom: -18px;
        height: 35px;
        width: 35px;
        background: $defaultColorEnd url('/img/site/plus-icon.svg') no-repeat center center / 15px;
        border-radius: 50%;

        @media only screen and (min-width: $smallBreakpoint) {

          right: 20px;
          bottom: 20px;
        }
      }

      &--noimage {

        background: $defaultColorStart url('/img/site/noimage-icon.svg') no-repeat center center / 75px;

        @media only screen and (min-width: $largeBreakpoint) {

          background-size: 100px;
        }
      }
    }

    &__block {

      padding: 25px 20px 20px;
      background: $white;

      @media only screen and (min-width: $smallBreakpoint) {

        padding: 20px;
        float: left;
        width: calc(100% - 225px);
        height: 136px;
      }

      @media only screen and (min-width: $mediumBreakpoint) {

        padding: 30px;
        height: 156px;
      }

      &__title {

        font-size: 16px;
        line-height: 20px;
        margin-bottom: 8px;
        display: block;
        color: $defaultColorText;

        @media only screen and (min-width: $smallBreakpoint) {

          font-size: 18px;
          line-height: 24px;
          margin-bottom: 12px;
        }
      }

      &__text {

        font-size: 12px;
        line-height: 14px;
        margin-bottom: 8px;

        @media only screen and (min-width: $smallBreakpoint) {

          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }

  .event {

    overflow: hidden;
    margin-bottom: 20px;

    @media only screen and (min-width: $mediumBreakpoint) {

      margin-bottom: 30px;
    }

    &__image {

      position: relative;
      height: 100px;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      display: block;

      @media only screen and (min-width: $smallBreakpoint) {

        float: left;
        width: 225px;
        height: 136px;
      }

      @media only screen and (min-width: $mediumBreakpoint) {

        height: 156px;
      }

      &__date {

        height: 100%;

        text-align: center;
        color: #fff;

        background: rgba(255,255,255,0);
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.33) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(100%, rgba(0,0,0,0.33)));
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.33) 100%);
        background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.33) 100%);
        background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.33) 100%);
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(0,0,0,0.33) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );

        &__info {

          position: relative;
          top: 46%;
          transform: translateY(-50%);

          &--day {

            font-size: 60px;
            font-weight: 700;
            line-height: 60px;
          }

          &--month {

            font-size: 18px;
            font-weight: 600;
            line-height: 18px;
            text-transform: uppercase;
          }
        }
      }

      &__plus {

        position: absolute;
        right: 10px;
        bottom: -18px;
        height: 35px;
        width: 35px;
        background: $defaultColorEnd url('/img/site/plus-icon.svg') no-repeat center center / 15px;
        border-radius: 50%;

        @media only screen and (min-width: $smallBreakpoint) {

          right: 20px;
          bottom: 20px;
        }
      }

      &--noimage {

        background-color: $defaultColorStart;
      }
    }

    &__block {

      padding: 20px;
      background: $white;

      @media only screen and (min-width: $smallBreakpoint) {

        float: left;
        width: calc(100% - 225px);
        height: 136px;
      }

      @media only screen and (min-width: $mediumBreakpoint) {

        height: 156px;
      }

      &__title {

        font-size: 16px;
        line-height: 20px;
        margin-bottom: 8px;
        display: block;
        color: $defaultColorText;

        @media only screen and (min-width: $smallBreakpoint) {

          font-size: 18px;
          line-height: 24px;
          margin-bottom: 12px;
        }
      }

      &__text {

        font-size: 12px;
        line-height: 14px;
        margin-bottom: 8px;

        @media only screen and (min-width: $smallBreakpoint) {

          font-size: 14px;
          line-height: 18px;
          margin-bottom: 12px;
        }
      }

      &__date {

        font-size: 10px;
        line-height: 12px;

        @media only screen and (min-width: $smallBreakpoint) {

          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }

}
