.header {

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  max-width: 320px;
  height: 100vh;
  background: $white;
  z-index: 3;
  transform: translateX(-100%);
  transition: transform ease-out .4s, box-shadow ease-out .4s;

  &--open {

    transform: translateX(0);
    -webkit-box-shadow: 0px 2px 20px 0px rgba(8,18,30,0.21);
    -moz-box-shadow: 0px 2px 20px 0px rgba(8,18,30,0.21);
    box-shadow: 0px 2px 20px 0px rgba(8,18,30,0.21);
  }

  @media only screen and (min-width: $mediumBreakpoint) {

    bottom: inherit;
    width: 20%;
    height: auto;
    min-height: 100vh;
    transform: translateX(0);
    position: relative;
    display: table-cell;
    vertical-align: top;
    -webkit-box-shadow: 0px 2px 20px 0px rgba(8,18,30,0.21);
    -moz-box-shadow: 0px 2px 20px 0px rgba(8,18,30,0.21);
    box-shadow: 0px 2px 20px 0px rgba(8,18,30,0.21);
  }

  @media only screen and (min-width: $largeBreakpoint) {

    width: 300px;
  }

  &__closemenu {

    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    line-height: 20px;
    padding: 15px;
    color: $defaultColorEnd;

    @media only screen and (min-width: $mediumBreakpoint) {

      display: none;
    }
  }

  &__top {

    margin: 30px;
    text-align: center;
  }

  &__bottom {

    text-align: center;

    @media only screen and (min-width: $mediumBreakpoint) {

      position: absolute;
      left: 0;
      right: 0;
      bottom: 30px;
      z-index: -1;
    }

    &__logo {
      max-width: 100%;
      padding: 0 40px;
    }
  }
}

.socialLinks {
  margin-top: 20px;
  text-align: center;

  @media only screen and (min-width: $mediumBreakpoint) {
    display: none;
  }

  .portal-icon {
    width: 100%;
    max-width: 100px;
  }

  .socialLinks-links {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    .header-icon {
      margin: 0 5px;

      img {
        vertical-align: middle;
        max-width: 14px;
      }
    }
  }
}

.menu {

  margin-bottom: 30px;
  background: $white;

  @media only screen and (min-width: $mediumBreakpoint) {

    margin-bottom: 163px;
  }

  & ul {

    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: $lightgrey solid 1px;
  }

  &__firstlevel {

    border-top: $lightgrey solid 1px;

    &__link {

      position: relative;
      display: block;
      padding: 15px 45px 15px 27px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      text-decoration: none;
      color: $defaultColorText;
      border-left: 3px solid $white;
      transition: border-color ease-out .2s;

      & > .icon-chevron-right {

        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 12px;
        line-height: 12px;
        color: $defaultColorStart;
        transition: transform ease-out .2s;
      }
    }

    & > ul {

      //display: none;
      padding: 0 30px;
      background: $body;
      //height: 0;
      //transition: height .3s ease-in-out;
      overflow: hidden;
    }

    &--active {

      .menu__firstlevel__link {

        border-color: $defaultColorEnd;

        & > .icon-chevron-right {

          transform: translateY(-50%) rotate(90deg);
        }
      }

      & > ul {
        display: block;

      }
    }
  }

  &__secondlevel {

    &__link {

      position: relative;
      padding-top: 15px;
      display: block;
      font-size: 16px;
      line-height: 22px;
      color: $defaultColorText;
      text-decoration: none;

      & > .icon-chevron-right {

        position: absolute;
        right: 0;
        top: calc(50% + 7.5px);
        transform: translateY(-50%);
        font-size: 12px;
        line-height: 12px;
        color: $defaultColorStart;
        transition: transform ease-out .2s;
      }
    }

    ul {

      display: none;
      margin-top: 10px;
      border-left: 2px solid $defaultColorEnd;
    }

    &--active .menu__secondlevel__link {

      color: $defaultColorEnd;

      & > .icon-chevron-right {

        transform: translateY(-50%) rotate(90deg);
      }
    }

    &--active > ul {

      display: block;
    }

    &:last-child {

      padding-bottom: 15px;
    }
  }

  &__thirdlevel {

    &__link {

      padding: 5px 0 5px 20px;
      display: block;
      font-size: 14px;
      line-height: 22px;
      color: $defaultColorText;
      text-decoration: none;
    }

    &--active .menu__thirdlevel__link {

      color: $defaultColorEnd;
    }
  }
}

.header__layout {

  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background: rgba(#000000, .5);
}
