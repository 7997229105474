.publication {

  &__header {

    position: relative;
    height: 320px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media only screen and (min-width: $smallBreakpoint) {

      height: 450px;
    }

    @media only screen and (min-width: $largeBreakpoint) {

      height: 634px;
    }

    &--noimage {

      height: 175px;
      background-color: $defaultColorStart;

      @media only screen and (min-width: $smallBreakpoint) {

        height: 233px;
      }

      @media only screen and (min-width: $largeBreakpoint) {

        height: 313px;
      }
    }

    #map,
    &--map {
      height: 175px;

      @media only screen and (min-width: $smallBreakpoint) {

        height: 233px;
      }

      @media only screen and (min-width: $largeBreakpoint) {

        height: 313px;
      }
    }

    &__layout {

      position: absolute;
      top: 25%;
      right: 0;
      bottom: 0;
      left: 0;

      background: rgba(255,255,255,0);
      background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.75) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(100%, rgba(0,0,0,0.75)));
      background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.75) 100%);
      background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.75) 100%);
      background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.75) 100%);
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(0,0,0,0.75) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
    }

    &__address {

      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      margin: 20px;
      color: $white;

      @media only screen and (min-width: $smallBreakpoint) {

        margin: 30px 30px 40px;
      }

      @media only screen and (min-width: $largeBreakpoint) {

        margin: 30px 30px 60px;
      }

      &::after {

        position: absolute;
        content: '';
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 90px;
        height: 2px;
        background-color: $defaultColorEnd;

        @media only screen and (min-width: $smallBreakpoint) {

          height: 4px;
          width: 120px;
        }

        @media only screen and (min-width: $mediumBreakpoint) {

          height: 6px;
        }
      }

      &__title {

        font-size: 16px;
        line-height: 20px;
        letter-spacing: .47px;
        max-height: 57px;
        overflow-y: hidden;
        font-weight: normal;
        margin: 0 0 20px;

        a {
          color: $white;
        }

        @media only screen and (min-width: $smallBreakpoint) {

          font-size: 24px;
          line-height: 32px;
          letter-spacing: .8px;
          max-height: 57px;
          margin: 0 0 30px;
        }

        @media only screen and (min-width: $mediumBreakpoint) {

          font-size: 22px;
          line-height: 30px;
          letter-spacing: 1px;
          max-height: 80px;
        }

        @media only screen and (min-width: $largeBreakpoint) {

          font-size: 30px;
          line-height: 37px;
          letter-spacing: 1.33px;
          max-height: 94px;
          margin: 0 0 26px;
        }
      }
    }

    &__block {

      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      margin: 20px;
      color: $white;

      @media only screen and (min-width: $smallBreakpoint) {

        margin: 30px 30px 40px;
      }

      @media only screen and (min-width: $largeBreakpoint) {

        margin: 30px 30px 60px;
      }

      &::after {

        position: absolute;
        content: '';
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 90px;
        height: 2px;
        background-color: $defaultColorEnd;

        @media only screen and (min-width: $smallBreakpoint) {

          height: 4px;
          width: 120px;
        }

        @media only screen and (min-width: $mediumBreakpoint) {

          height: 6px;
        }
      }

      &__title {

        font-size: 20px;
        line-height: 28px;
        letter-spacing: .47px;
        max-height: 57px;
        overflow-y: hidden;
        font-weight: normal;
        margin: 0 0 20px;

        @media only screen and (min-width: $smallBreakpoint) {

          font-size: 24px;
          line-height: 32px;
          letter-spacing: .8px;
          max-height: 57px;
          margin: 0 0 30px;
        }

        @media only screen and (min-width: $mediumBreakpoint) {

          font-size: 32px;
          line-height: 40px;
          letter-spacing: 1px;
          max-height: 80px;
        }

        @media only screen and (min-width: $largeBreakpoint) {

          font-size: 40px;
          line-height: 47px;
          letter-spacing: 1.33px;
          max-height: 94px;
          margin: 0 0 46px;
        }
      }

      &__subtitle {

        font-size: 16px;
        line-height: 22px;
        letter-spacing: .53px;
        font-weight: normal;
        margin: 0 0 30px;

        @media only screen and (min-width: $smallBreakpoint) {

          font-size: 18px;
          line-height: 24px;
          letter-spacing: .70px;
        }

        @media only screen and (min-width: $mediumBreakpoint) {

          font-size: 22px;
          line-height: 28px;
          letter-spacing: .80px;
        }

        @media only screen and (min-width: $largeBreakpoint) {

          font-size: 26px;
          line-height: 36px;
          letter-spacing: .87px;
        }
      }
    }
  }

  &__breadcrumb {

    text-align: center;
    padding: 10px 30px;
    font-size: 12px;
    line-height: 16px;

    @media only screen and (min-width: $smallBreakpoint) {

      font-size: 14px;
      line-height: 28px;
    }

    @media only screen and (min-width: $mediumBreakpoint) {

      font-size: 16px;
      line-height: 34px;
    }

    &__link {

      color: $defaultColorText;

      &::after {

        content: ' >';
      }

      &:hover {
        opacity: $hoverOpacity;
      }

      &--active {

        color: $defaultColorEnd;
        font-weight: 600;

        &::after {

          content: '';
        }
      }
    }
  }

  &__wrapper {

    background: $white;
    padding: 20px 0;

    @media only screen and (min-width: $smallBreakpoint) {

      padding: 20px;
    }

    @media only screen and (min-width: $mediumBreakpoint) {

      padding: 40px;
    }

    @media only screen and (min-width: $largeBreakpoint) {

      padding: 60px;
    }

    &__content {

      max-width: 1080px;
      margin: 0 auto;

      & .reading-title,
      & .floating-text .reading-title
      & .article_form .reading-title {

        font-size: 18px;
        line-height: 28px;
        margin: 0 0 10px;
        font-weight: 600;
        padding: 0 20px;

        @media only screen and (min-width: $smallBreakpoint) {

          font-size: 20px;
          line-height: 28px;
          padding: 0;
        }

        @media only screen and (min-width: $mediumBreakpoint) {

          margin-bottom: 20px;
        }

        @media only screen and (min-width: $largeBreakpoint) {

          font-size: 30px;
          line-height: 34px;
        }
      }

      & > .reading-text {

        margin-bottom: 20px;

        @media only screen and (min-width: $mediumBreakpoint) {

          margin-bottom: 40px;
        }

        @media only screen and (min-width: $largeBreakpoint) {

          margin-bottom: 50px;
        }
      }

      & .reading-text,
      & .floating-text
      & .article_form .reading-text {

        font-size: 14px;
        line-height: 24px;
        font-weight: 300;
        padding: 0 20px;

        @media only screen and (min-width: $smallBreakpoint) {

          font-size: 16px;
          line-height: 28px;
          padding: 0;
        }

        @media only screen and (min-width: $largeBreakpoint) {

          font-size: 20px;
          line-height: 34px;
        }

        p,
        ul,
        ol {
          margin-bottom: 15px;
        }

        ul li {
          list-style-type: disc;
        }

        ol li {
          list-style-type: decimal;
        }

        a {

          text-decoration: underline;

          &:hover {
            opacity: $hoverOpacity;
          }
        }

        table {
          display: table;
          width: 100%;
          border-width: 0;
          border-collapse: collapse;
          margin-top: 0;
          margin-bottom: 15px;

          thead tr th {
            font-weight: normal;
            background-color: #888;
            color: #fff;
            text-align: left;
            padding: 8px 0 8px 10px;
          }

          tr td {
            border-bottom: 1px solid #ddd;
            padding: 8px 0 8px 10px;
          }

          tr:nth-child(even) {
            background-color: #efefef;
          }
        }

        .block-table {
          overflow-x: scroll;
        }

      }

      & > .floating-text {

        margin-bottom: 20px;

        @media only screen and (min-width: $mediumBreakpoint) {

          margin-bottom: 40px;
        }

        @media only screen and (min-width: $largeBreakpoint) {

          margin-bottom: 50px;
        }

        &--left > img {

          width: 100%;

          @media only screen and (min-width: $smallBreakpoint) {

            float: left;
            width: calc(50% - 10px);
            margin: 0 20px 20px 0;
          }

          @media only screen and (min-width: $mediumBreakpoint) {

            width: calc(50% - 20px);
            margin: 0 40px 40px 0;
          }
        }

        &--right > img {

          width: 100%;

          @media only screen and (min-width: $smallBreakpoint) {

            float: right;
            width: calc(50% - 20px);
            margin: 0 0 20px 20px;
          }

          @media only screen and (min-width: $mediumBreakpoint) {

            width: calc(50% - 20px);
            margin: 0 0 40px 40px;
          }
        }
      }

      & > .image {
        text-align: center;
        margin-bottom: 20px;

        @media only screen and (min-width: $mediumBreakpoint) {

          margin-bottom: 40px;
        }

        @media only screen and (min-width: $largeBreakpoint) {

          margin-bottom: 50px;
        }

        img {

          max-width: 100%;
        }

        .image__caption {

          margin-bottom: -12px;
          padding-right: 10px;
          font-size: 10px;
          line-height: 12px;
          text-align: center;

          @media only screen and (min-width: $smallBreakpoint) {

            padding-right: 0;
          }
        }
      }
    }
  }
}
