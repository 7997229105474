.main {

  width: 100%;
  float: left;
  z-index: 0;
  transition: width ease-out .4s;
  padding-bottom: 78px;
  position: relative;
  min-height: 100vh;

  @media only screen and (min-width: $smallBreakpoint) {

    padding-bottom: 59px;
  }

  @media only screen and (min-width: $mediumBreakpoint) {

    position: relative;
    display: table-cell;
    vertical-align: top;
    width: 80%;
    float: inherit;
  }

  @media only screen and (min-width: $largeBreakpoint) {

    width: calc(100% - 300px);
  }
}
