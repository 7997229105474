.button {
  position: relative;
  color: white !important;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  background-color: $defaultColorStart;
  border-radius: 63px;
  border: none;
  padding: 10px;
  display: block;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: $hoverOpacity;
  }

  @media only screen and (min-width: $smallBreakpoint) {

    padding: 10px 20px;
  }

  @media only screen and (min-width: $largeBreakpoint) {

    font-size: 16px;
    line-height: 22px;
    padding: 20px 50px;
  }

  &--error {

    font-size: 18px;
    background-color: #B32B26 !important;

    span {
      display: none;
    }

    i.icon-error {
      display: inline;
    }

    i.icon-success {
      display: none;
    }
  }

  &--success {

    font-size: 18px;
    background-color: #00B007 !important;

    span {
      display: none;
    }

    i.icon-error {
      display: none;
    }

    i.icon-success {
      display: inline;
    }
  }

  &--submit {
    i {
      display: none;
    }
  }

}
