.calendar {

  position: relative;
  float: left;
  width: 100%;
  color: $white;

  @media only screen and (min-width: $smallBreakpoint) {

    height: 450px;
    width: 450px;
    max-width: 40%;
  }

  @media only screen and (min-width: $largeBreakpoint) {

    height: 635px;
  }

  &__gradient {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: $defaultColorStart !important;
    background: -moz-linear-gradient(-45deg, $defaultColorStart 0%, $defaultColorEnd 100%) !important;
    background: -webkit-gradient(left top, right bottom, color-stop(0%, $defaultColorStart), color-stop(100%, $defaultColorEnd)) !important;
    background: -webkit-linear-gradient(-45deg, $defaultColorStart 0%, $defaultColorEnd 100%) !important;
    background: -o-linear-gradient(-45deg, $defaultColorStart 0%, $defaultColorEnd 100%) !important;
    background: -ms-linear-gradient(-45deg, $defaultColorStart 0%, $defaultColorEnd 100%) !important;
    background: linear-gradient(135deg, $defaultColorStart 0%, $defaultColorEnd 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$defaultColorStart', endColorstr='$defaultColorEnd', GradientType=1) !important;

    z-index: -2;
  }

  &__layout {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($black, .5);

    z-index: -1;
  }

  &__nocalendar {

    width: 100%;
    padding: 20px;

    @media only screen and (min-width: $smallBreakpoint) {

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }

    @media only screen and (min-width: $largeBreakpoint) {

      width: calc(100% - 60px);
    }

    .icon-calendar {

      width: 80px;
      height: 80px;
      margin: 0 auto 50px;
      background: url('/img/site/calendar-icon.svg') no-repeat center center / contain;
    }
  }

  &__wrapper {

    width: 100%;
    padding: 20px;

    @media only screen and (min-width: $largeBreakpoint) {

      padding: 30px;
    }

    .flickity-viewport {

      position: relative;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }

  &__buttons {

    overflow: hidden;
    margin-bottom: 20px;

    @media only screen and (min-width: $largeBreakpoint) {

      margin-bottom: 40px;
    }

    &__button {

      float: left;
      width: 50%;
      text-align: center;
      font-size: 11px;
      line-height: 13px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid $white;
      transition: background ease-out .2s, color ease-out .2s;
      cursor: pointer;

      @media only screen and (min-width: $smallBreakpoint) {

        width: 50%;
      }

      @media only screen and (min-width: $largeBreakpoint) {

        font-size: 16px;
        line-height: 22px;
        padding: 15px 10px;
      }

      &:nth-child(1) {

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:nth-child(2) {

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &--active {

        background: $white;
        color: $defaultColorText;
      }
    }
  }

  &__calendar-view {

    width: 100%;
    //margin-left: 35px;
    //margin-left: 50px;
  }

  &__events {

    width: 100%;

    .event {

      overflow: hidden;
      margin-bottom: 15px;

      @media only screen and (min-width: $largeBreakpoint) {

        margin-bottom: 30px;
      }

      &__date {

        float: left;
        width: 45px;
        height: 45px;
        border-radius: 60px;
        border: 1px solid $white;
        margin-right: 20px;
        text-align: center;
        padding: 5px;

        @media only screen and (min-width: $largeBreakpoint) {

          width: 60px;
          height: 60px;
          border: 2px solid $white;
        }

        .day {

          font-size: 18px;
          line-height: 18px;

          @media only screen and (min-width: $largeBreakpoint) {

            font-size: 26px;
            line-height: 28px;
          }
        }

        .month {

          font-size: 10px;
          line-height: 10px;
          font-weight: 300;

          @media only screen and (min-width: $largeBreakpoint) {

            font-size: 14px;
            line-height: 14px;
          }
        }
      }

      &__infos {

        float: left;
        width: calc(100% - 80px);

        .name {

          font-size: 12px;
          line-height: 14px;
          max-height: 36px;
          overflow-y: hidden;
          font-weight: 300;
          margin-bottom: 5px;
          color: #FFF;

          @media only screen and (min-width: $largeBreakpoint) {

            font-size: 16px;
            line-height: 22px;
            max-height: 44px;
            margin-bottom: 10px;
          }
        }

        .link {

          display: block;
          color: #FFF;
          font-size: 10px;
          font-weight: 300;
          line-height: 12px;

          &:hover {
            opacity: $hoverOpacity;
            text-decoration: underline;
          }

          @media only screen and (min-width: $largeBreakpoint) {

            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }

    .button {

      @media only screen and (min-width: $largeBreakpoint) {

        margin-top: 40px;
      }
    }
  }

  &__legend {

    display: table;
    font-family: $calendarFont;
    font-size: 8px;
    line-height: 10px;
    margin: 10px 0 15px 0;

    @media only screen and (min-width: $largeBreakpoint) {

      font-size: 10px;
      line-height: 13px;
      margin: 15px 0 20px 0;
    }

    div {

      display: table-cell;
      padding: 0 6px 0 20px;
      vertical-align: middle;

      @media only screen and (min-width: $largeBreakpoint) {

        //padding: 0 20px 0 30px;
      }

      &:last-child {

        padding-right: 0;
      }

      // Holiday
      &.event_holiday {

        position: relative;

        &::after {

          content: '';
          position: absolute;
          z-index: -1;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 14px;
          height: 14px;
          background-size: 14px 14px;
          background-image: url('/img/site/event-holiday-legend.svg');

          @media only screen and (min-width: $largeBreakpoint) {

            //width: 18px;
            //height: 18px;
          }
        }
      }

      // Pedago 1
      &.event_pedago1 {

        position: relative;

        &::after {

          content: '';
          position: absolute;
          z-index: -1;
          top: 50%;
          left: 0;
          width: 14px;
          height: 14px;
          transform: translateY(-50%);
          background-size: 14px 14px;
          background-image: url('/img/site/event-pedago1-legend.svg');

          @media only screen and (min-width: $largeBreakpoint) {


          }
        }
      }

      // Pedago 2
      &.event_pedago2 {

        position: relative;

        &::after {

          content: '';
          position: absolute;
          z-index: -1;
          top: 50%;
          left: 0;
          width: 14px;
          height: 14px;
          transform: translateY(-50%);
          background-size: 14px 14px;
          background-image: url('/img/site/event-pedago2-legend.svg');

          @media only screen and (min-width: $largeBreakpoint) {

          }
        }
      }

      // Pedago 3
      &.event_pedago3 {

        position: relative;

        &::after {

          content: '';
          position: absolute;
          z-index: -1;
          top: 50%;
          left: 0;
          width: 14px;
          height: 14px;
          transform: translateY(-50%);
          background-size: 14px 14px;
          background-image: url('/img/site/event-pedago3-legend.svg');

          @media only screen and (min-width: $largeBreakpoint) {

          }
        }
      }

    }
  }
}
