.toolbar {

  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;

  &__burger {

    width: 50px;
    text-align: center;
    font-size: 14px;
    line-height: 40px;
    cursor: pointer;

    @media only screen and (min-width: $mediumBreakpoint) {

      display: none;
    }
  }

  &__title {

    width: calc(100% - 90px);
    font-size: 16px;
    line-height: 20px;
    padding: 10px 20px;
    margin: 0;
    font-family: $secondaryFont;
    color: $defaultColorText;
    font-weight: 400;

    @media only screen and (min-width: $mediumBreakpoint) {

      width: calc(100% - 75px);
      font-size: 30px;
      line-height: 35px;
      padding: 20px;
    }
  }

  &__searchButton {

    width: 40px;
    height: 40px;
    background: $defaultColorStart url('/img/site/search-icon.svg') no-repeat center center / 16px;
    cursor: pointer;

    @media only screen and (min-width: $mediumBreakpoint) {

      width: 75px;
      height: 75px;
      background-size: 25px;
    }
  }

  &__socialLinks {
    display: none;
    align-items: center;
    padding: 0 20px;

    @media only screen and (min-width: $mediumBreakpoint) {
      display: flex;
    }

    .toobar-icon {
      margin: 0 5px;

      img {
        vertical-align: middle;
        max-width: 30px;
      }

      &:first-child img {
        max-width: 160px;
      }
    }
  }
}
