.cta {

  background: #fff !important;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;

  @media only screen and (min-width: $mediumBreakpoint) {

    padding: 30px;
    margin-bottom: 30px;
  }

  .button {
    display: inline-block;
    width: fit-content;
    padding: 10px 30px;
    border-radius: 20px;
    font-weight: 300;
    font-size: 14px;
    text-transform: none;
  }

  &__title {

    color: $black;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    //text-transform: uppercase;
    margin: 0 0 15px;
    letter-spacing: .3px;

    @media only screen and (min-width: $smallBreakpoint) {

      font-size: 24px;
      line-height: 32px;
    }

    @media only screen and (min-width: $mediumBreakpoint) {

      //font-weight: 300;
      font-size: 28px;
      line-height: 32px;
    }
  }

  &__text {

    color: $black;
    font-size: 14px;
    line-height: 150%;
    font-weight: 300;
    margin-bottom: 20px;

    @media only screen and (min-width: $smallBreakpoint) {

      font-size: 16px;
    }
  }
}
