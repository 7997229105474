.dashboard {

  overflow: hidden;

  &__articles {

    overflow: hidden;
    float: left;
    width: 100%;
    height: 320px;

    @media only screen and (min-width: $smallBreakpoint) {

      width: calc(100% - 450px);
      min-width: 60%;
      height: 450px;
    }

    @media only screen and (min-width: $largeBreakpoint) {

      height: 635px;
    }
  }

  &__article {

    position: relative;
    width: 100%;
    height: 320px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @media only screen and (min-width: $smallBreakpoint) {

      height: 450px;
    }

    @media only screen and (min-width: $largeBreakpoint) {

      height: 635px;
    }

    &__layout {

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background: rgba(255,255,255,0);
      background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.33) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(100%, rgba(0,0,0,0.33)));
      background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.33) 100%);
      background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.33) 100%);
      background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.33) 100%);
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(0,0,0,0.33) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
    }

    &__block {

      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 20px;

      @media only screen and (min-width: $mediumBreakpoint) {

        margin: 30px 40px;
      }

      & > h2 {

        width: calc(100% - 55px);
        color: $white;
        font-size: 16px;
        line-height: 22px;
        margin: 0 0 10px;
        font-weight: 700;
        letter-spacing: .53px;
        text-shadow: 0 0 5px rgba(50, 50, 50, 1);

        & > a {
          color: #FFFFFF;
        }

        @media only screen and (min-width: $mediumBreakpoint) {

          width: calc(100% - 70px);
          font-size: 20px;
          line-height: 24px;
          letter-spacing: .85px;
          margin: 0 0 10px;
        }

        @media only screen and (min-width: $largeBreakpoint) {

          width: calc(100% - 80px);
          font-size: 26px;
          line-height: 36px;
          letter-spacing: .85px;
          margin: 0 0 20px;
        }
      }

      & > p {

        width: calc(100% - 55px);
        color: $white;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: .47px;
        text-shadow: 0 0 5px rgba(100, 100, 100, 1);

        & > a {
          margin-top: 10px;
          color: $white;
        }

        @media only screen and (min-width: $mediumBreakpoint) {

          width: calc(100% - 70px);
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 1px;
        }

        @media only screen and (min-width: $largeBreakpoint) {

          width: calc(100% - 80px);
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 1px;
        }

        & > a {

          display: block;

          &:hover {
            opacity: $hoverOpacity;
          }

          @media only screen and (min-width: $mediumBreakpoint) {

            display: inline-block;
          }
        }
      }

      &__link {

        position: absolute;
        width: 40px;
        height: 40px;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        background: $defaultColorEnd url('/img/site/plus-icon.svg') no-repeat center center / 14px;

        &:hover {
          opacity: $hoverOpacity;
        }

        @media only screen and (min-width: $mediumBreakpoint) {

          width: 50px;
          height: 50px;
          background-size: 18px;
        }

        @media only screen and (min-width: $largeBreakpoint) {

          width: 60px;
          height: 60px;
          background-size: 20px;
        }
      }
    }
  }

  .flickity-prev-next-button {

    &.previous:disabled {

      opacity: 0;
    }

    &.next:disabled {

      opacity: 0;
    }
  }
}
