.footer {

  background: $white;
  padding: 20px 30px;
  font-size: 14px;
  line-height: 19px;
  color: $black;
  text-align: center;
  overflow: hidden;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  @media only screen and (min-width: $tinyBreakpoint) {

    padding: 20px;
    text-align: left;
  }

  @media only screen and (min-width: $largeBreakpoint) {

    padding: 20px 50px;
  }

  &__left {

    @media only screen and (min-width: $tinyBreakpoint) {

      float: left;

      &__element {

        display: inline-block;

        &:first-child::after {

          content: ' - ';
        }
      }
    }
  }

  &__right {

    text-align: center;

    @media only screen and (min-width: $tinyBreakpoint) {

      float: right;
      text-align: right;
    }
  }
}
