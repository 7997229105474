.video {

  margin-bottom: 20px;

  @media only screen and (min-width: $mediumBreakpoint) {

    margin-bottom: 40px;
  }

  @media only screen and (min-width: $largeBreakpoint) {

    margin-bottom: 50px;
  }

  &__wrapper {

    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__caption {

    margin-top: 10px;
    margin-bottom: -12px;
    padding-right: 10px;
    font-size: 10px;
    line-height: 12px;
    text-align: right;

    @media only screen and (min-width: $smallBreakpoint) {

      padding-right: 0;
    }
  }

}
