.contact {

  padding: 0 20px;

  @media only screen and (min-width: $smallBreakpoint) {

    padding: 0;
  }

  &__title {

    margin: 0 0 20px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;

    @media only screen and (min-width: $smallBreakpoint) {

      font-size: 20px;
      line-height: 28px;
    }

    @media only screen and (min-width: $largeBreakpoint) {

      font-size: 32px;
      line-height: 36px;
    }
  }

  &__addresses {

    display: flex;
    flex-wrap: wrap;
  }

  &__address {

    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #EAEEF1;

    @media only screen and (min-width: $smallBreakpoint) {

      width: calc(50% - 10px);
      margin-right: 20px;

      &:nth-child(2n+2) {

        margin-right: 0;
      }

      &__single {
        width: 100%;
        margin-right: 0;
      }

    }

    &__description {

      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 15px;

      @media only screen and (min-width: $smallBreakpoint) {

        font-size: 18px;
        line-height: 22px;
      }

      @media only screen and (min-width: $largeBreakpoint) {

        font-size: 22px;
        line-height: 24px;
      }
    }

    &__infos {

      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      margin-bottom: 15px;

      a {
        text-decoration: underline;
      }

      @media only screen and (min-width: $smallBreakpoint) {

        font-size: 16px;
        line-height: 24px;
      }

      @media only screen and (min-width: $largeBreakpoint) {

        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  &__method {

    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    margin-bottom: 15px;

    @media only screen and (min-width: $smallBreakpoint) {

      font-size: 16px;
      line-height: 20px;
    }

    @media only screen and (min-width: $largeBreakpoint) {

      font-size: 18px;
      line-height: 24px;
    }

    a {
      color: $defaultColorText;
    }

    strong {

      font-weight: 400;
    }

    &__title {
      margin-bottom: 10px;
    }

    &__email,
    &__phone {

      margin-bottom: 10px;

      span {

        font-size: 12px;

        @media only screen and (min-width: $largeBreakpoint) {

          font-size: 14px;
        }
      }
    }

    &__email a {

      text-decoration: underline;
    }
  }

}
