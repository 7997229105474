#map {

  width: 100%;
  height: 320px;

  @media only screen and (min-width: $mediumBreakpoint) {

    height: 350px;
  }

  @media only screen and (min-width: $largeBreakpoint) {

    height: 400px;
  }
}
