.file {

  position: relative;
  display: block;
  background-color: $body;
  padding: 20px 20px 20px 85px;
  margin-bottom: 20px;
  line-height: 18px;

  @media only screen and (min-width: $smallBreakpoint) {

    line-height: 24px;
  }

  @media only screen and (min-width: $mediumBreakpoint) {

    margin-bottom: 40px;
    padding: 25px 25px 25px 95px;
    line-height: 34px;
  }

  @media only screen and (min-width: $largeBreakpoint) {

    margin-bottom: 50px;
  }

  &:hover {
    opacity: $hoverOpacity;
  }

  &::after {

    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e924";
    font-size: 40px;
    color: $defaultColorEnd;

    @media only screen and (min-width: $mediumBreakpoint) {

      font-size: 50px;
    }
  }

  &__title {

    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    color: $defaultColorText;

    &:hover {
      opacity: $hoverOpacity;
    }

    @media only screen and (min-width: $smallBreakpoint) {

      font-size: 16px;
      line-height: 24px;
    }

    @media only screen and (min-width: $mediumBreakpoint) {

      font-size: 20px;
      line-height: 34px;
    }
  }

  &__text {

    font-size: 12px;
    line-height: 24px;
    font-weight: 300;
    font-style: italic;
    color: $defaultColorText;

    @media only screen and (min-width: $smallBreakpoint) {

      font-size: 14px;
      line-height: 24px;
    }

    @media only screen and (min-width: $mediumBreakpoint) {

      font-size: 16px;
      line-height: 34px;
    }
  }
}
