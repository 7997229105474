/*
 * 		Common Styles
 * 		-------------
 */

html,
body {

  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: $primaryFont;
  font-size: 20px;
  line-height: 34px;
  position: relative;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {

  background: $body;
  color: $defaultColorText;

  @media only screen and (min-width: $smallBreakpoint) {

    display: table;
    width: 100%;
    min-height: 100vh;
  }
}

*{
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -ms-box-sizing:border-box;
  box-sizing:border-box;
}

div,
p,
header,
nav,
aside,
section,
article {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

textarea,
input,
select,
button{

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {

    outline: none;
  }

  line-height: 1em;
}

select::-ms-expand {
  display: none;
}

a {

  color: $defaultColorEnd;
  text-decoration: none;
  border: none;
  transition: all ease-out .2s;
}

.clearfix:after {

  content: "";
  display: block;
  clear: both;
}

.ellipsis {

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-center {

  text-align: center;
}

.text-right {

  text-align: right;
}

@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/icomoon.eot');
  src:  url('/fonts/icomoon.eot') format('embedded-opentype'),
  url('/fonts/icomoon.ttf') format('truetype'),
  url('/fonts/icomoon.woff') format('woff'),
  url('/fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-burger:before {
  content: "\e900";
}
.icon-chevron-bottom:before {
  content: "\e901";
}
.icon-chevron-left:before {
  content: "\e902";
}
.icon-chevron-right:before {
  content: "\e903";
}
.icon-close:before {
  content: "\e904";
}
.icon-file-empty:before {
  content: "\e924";
}
.icon-error:before {
  content: "\ea0f";
}
.icon-success:before {
  content: "\ea10";
}

// Clearfix
// Usage: @extend %clearfix;
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

