/*
CSDL BASE
 */
.cookie-confirmation {
  display: none;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  transform: translate3d(-50%, 0, 0);
  background: rgba(249, 250, 250, 0.95);
  border: 0;
  line-height: 18px;
  padding: 20px;
  position: fixed;
  text-align: left;
  text-shadow: none;
  top: auto;
  z-index: 100000000;
  bottom: 15px;
  left: 50%;
  max-width: 580px; }
  @media (max-width: 767px) {
    .cookie-confirmation {
      bottom: 0;
      left: 0;
      transform: none;
      max-width: 100%;
      border-radius: 0; } }
  .cookie-confirmation.is-active {
    display: block; }
  .cookie-confirmation .cookie-confirmation-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-font-smoothing: antialiased; }
  .cookie-confirmation .cookie-confirmation-text {
    font-size: 14.5px;
    line-height: 21px;
    color: #33475b;
    text-align: left;
    font-weight: 500;
    margin: 0;
    padding-right: 20px; }
    .cookie-confirmation .cookie-confirmation-text a {
      color: #FC9E30; }
  .cookie-confirmation .confirmation-button {
    margin: 0;
    font-size: 14.5px;
    color: #fff;
    font-weight: 500;
    background: #FC9E30;
    border: 0;
    border-radius: 4px;
    padding: 7px 16px; }

/*
CSDL IMPORTS
 */
.lg-sub-html, .lg-toolbar {
  background-color: rgba(0, 0, 0, 0.65); }

#lg-counter, .lg-outer .lg-video-cont {
  vertical-align: middle;
  display: inline-block; }

@font-face {
  font-family: lg;
  src: url(/fonts/lg.eot?n1z373);
  src: url(/fonts/lg.eot?#iefixn1z373) format("embedded-opentype"), url(/fonts/lg.woff?n1z373) format("woff"), url(/fonts/lg.ttf?n1z373) format("truetype"), url(/fonts/lg.svg?n1z373#lg) format("svg");
  font-weight: 400;
  font-style: normal; }

.lg-icon {
  font-family: lg;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 2px;
  color: #FF9100;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080; }

.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: .5; }

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
  color: #ffbd66; }

.lg-actions .lg-next {
  right: 20px; }

.lg-actions .lg-next:before {
  content: "\e095"; }

.lg-actions .lg-prev {
  left: 20px; }

.lg-actions .lg-prev:after {
  content: "\e094"; }

@-webkit-keyframes lg-right-end {
  0%, 100% {
    left: 0; }
  50% {
    left: -30px; } }

@-moz-keyframes lg-right-end {
  0%, 100% {
    left: 0; }
  50% {
    left: -30px; } }

@-ms-keyframes lg-right-end {
  0%, 100% {
    left: 0; }
  50% {
    left: -30px; } }

@keyframes lg-right-end {
  0%, 100% {
    left: 0; }
  50% {
    left: -30px; } }

@-webkit-keyframes lg-left-end {
  0%, 100% {
    left: 0; }
  50% {
    left: 30px; } }

@-moz-keyframes lg-left-end {
  0%, 100% {
    left: 0; }
  50% {
    left: 30px; } }

@-ms-keyframes lg-left-end {
  0%, 100% {
    left: 0; }
  50% {
    left: 30px; } }

@keyframes lg-left-end {
  0%, 100% {
    left: 0; }
  50% {
    left: 30px; } }

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end .3s;
  -o-animation: lg-right-end .3s;
  animation: lg-right-end .3s;
  position: relative; }

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end .3s;
  -o-animation: lg-left-end .3s;
  animation: lg-left-end .3s;
  position: relative; }

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.lg-toolbar .lg-icon {
  color: #FF9100;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: 0;
  -webkit-transition: color .2s linear;
  -o-transition: color .2s linear;
  transition: color .2s linear; }

.lg-toolbar .lg-icon:hover {
  color: #ffbd66; }

.lg-toolbar .lg-close:after {
  content: "\e070"; }

.lg-toolbar .lg-download:after {
  content: "\e0f2"; }

.lg-sub-html {
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080; }

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: 700; }

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0; }

#lg-counter {
  color: #FFFFFF;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px; }

.lg-next, .lg-prev, .lg-toolbar {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important; }

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px; }

.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px; }

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color .25s ease;
    -o-transition: border-color .25s ease;
    transition: border-color .25s ease; } }

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707; }

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px; }

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0; }

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom .25s ease;
  -o-transition: bottom .25s ease;
  transition: bottom .25s ease; }

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px; }

.lg-outer .lg-toogle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px; }

.lg-outer .lg-toogle-thumb:after {
  content: "\e1ff"; }

.lg-outer .lg-toogle-thumb:hover {
  color: #FFF; }

.lg-outer .lg-video-cont {
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px; }

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative; }

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer; }

.lg-outer .lg-has-html5 .lg-video-play,
.lg-outer .lg-has-vimeo .lg-video-play {
  background: url(/img/site/video-play.png) no-repeat;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: .8; }

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1; }

.lg-outer .lg-has-youtube .lg-video-play {
  background: url(/img/site/youtube-play.png) no-repeat; }

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url(/img/site/youtube-play.png) 0 -60px no-repeat; }

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0; }

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible; }

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 80ms ease 0s;
  -moz-transition: opacity 80ms ease 0s;
  -o-transition: opacity 80ms ease 0s;
  transition: opacity 80ms ease 0s; }

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0; }

.lg-progress-bar.lg-start .lg-progress {
  width: 100%; }

.lg-show-autoplay .lg-progress-bar {
  opacity: 1; }

.lg-autoplay-button:after {
  content: "\e01d"; }

.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

#lg-zoom-in:after {
  content: "\e311"; }

#lg-actual-size {
  font-size: 20px; }

#lg-actual-size:after {
  content: "\e033"; }

#lg-zoom-out {
  opacity: .5;
  pointer-events: none; }

#lg-zoom-out:after {
  content: "\e312"; }

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto; }

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px; }

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible; }

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px; }

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px #fff inset; }

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity .15s ease 0s, -webkit-transform .15s ease 0s;
  -moz-transition: opacity .15s ease 0s, -moz-transform .15s ease 0s;
  -o-transition: opacity .15s ease 0s, -o-transform .15s ease 0s;
  transition: opacity .15s ease 0s, transform .15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0); }

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%; }

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow .3s ease 0s;
  -o-transition: box-shadow .3s ease 0s;
  transition: box-shadow .3s ease 0s;
  width: 12px; }

.lg-outer .lg-pager:focus, .lg-outer .lg-pager:hover {
  box-shadow: 0 0 0 8px #fff inset; }

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0; }

.lg-outer, .lg-outer .lg, .lg-outer .lg-inner {
  width: 100%;
  height: 100%; }

.lg-fullscreen:after {
  content: "\e20c"; }

.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d"; }

.group:after, .group:before {
  display: table;
  content: "";
  line-height: 0; }

.group:after {
  clear: both; }

.lg-outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  -webkit-transition: opacity .15s ease 0s;
  -o-transition: opacity .15s ease 0s;
  transition: opacity .15s ease 0s; }

.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.lg-outer.lg-visible {
  opacity: 1; }

.lg-outer.lg-css3 .lg-item.lg-current, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-prev-slide {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important; }

.lg-outer.lg-css3.lg-dragging .lg-item.lg-current, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1; }

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.lg-outer .lg {
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%; }

.lg-outer .lg-inner {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap; }

.lg-outer .lg-item {
  background: url(/img/site/loading.gif) center center no-repeat;
  display: none !important; }

.lg-outer.lg-css .lg-current, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide, .lg-outer.lg-css3 .lg-prev-slide {
  display: inline-block !important; }

.lg-outer .lg-img-wrap, .lg-outer .lg-item {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%; }

.lg-outer .lg-img-wrap:before, .lg-outer .lg-item:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px; }

.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

.lg-outer .lg-item.lg-complete {
  background-image: none; }

.lg-outer .lg-item.lg-current {
  z-index: 1060; }

.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important; }

.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity .15s ease 0s;
  -o-transition: opacity .15s ease 0s;
  transition: opacity .15s ease 0s; }

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1; }

.lg-outer .lg-empty-html, .lg-outer.lg-hide-download #lg-download {
  display: none; }

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity .15s ease 0s;
  -o-transition: opacity .15s ease 0s;
  transition: opacity .15s ease 0s; }

.lg-backdrop.in {
  opacity: 0.5; }

.lg-css3.lg-no-trans .lg-current, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-prev-slide {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item, .lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item {
  opacity: 0; }

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1; }

.lg-css3.lg-fade .lg-item.lg-current, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-prev-slide {
  -webkit-transition: opacity .1s ease 0s;
  -moz-transition: opacity .1s ease 0s;
  -o-transition: opacity .1s ease 0s;
  transition: opacity .1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

/**
 * Zabuto Calendar
 */
div.zabuto_calendar {
  margin: 0;
  padding: 0; }

div.zabuto_calendar .table {
  width: 100%;
  margin: 0;
  padding: 0; }

div.zabuto_calendar .table th,
div.zabuto_calendar .table td {
  padding: 4px 2px;
  text-align: center; }

div.zabuto_calendar .table tr th,
div.zabuto_calendar .table tr td {
  background-color: #ffffff; }

div.zabuto_calendar .table tr.calendar-month-header th {
  background-color: #fafafa; }

div.zabuto_calendar .table tr.calendar-month-header th span {
  cursor: pointer;
  display: inline-block;
  padding-bottom: 10px; }

div.zabuto_calendar .table tr.calendar-dow-header th {
  background-color: #f0f0f0; }

div.zabuto_calendar .table tr:last-child {
  border-bottom: 1px solid #dddddd; }

div.zabuto_calendar .table tr.calendar-month-header th {
  padding-top: 12px;
  padding-bottom: 4px; }

div.zabuto_calendar .table-bordered tr.calendar-month-header th {
  border-left: 0;
  border-right: 0; }

div.zabuto_calendar .table-bordered tr.calendar-month-header th:first-child {
  border-left: 1px solid #dddddd; }

div.zabuto_calendar div.calendar-month-navigation {
  cursor: pointer;
  margin: 0;
  padding: 0;
  padding-top: 5px; }

div.zabuto_calendar tr.calendar-dow-header th,
div.zabuto_calendar tr.calendar-dow td {
  width: 14%; }

div.zabuto_calendar .table tr td div.day {
  margin: 0;
  padding-top: 7px;
  padding-bottom: 7px; }

/* actions and events */
div.zabuto_calendar .table tr td.event div.day,
div.zabuto_calendar ul.legend li.event {
  background-color: #fff0c3; }

div.zabuto_calendar .table tr td.dow-clickable,
div.zabuto_calendar .table tr td.event-clickable {
  cursor: pointer; }

/* badge */
div.zabuto_calendar .badge-today,
div.zabuto_calendar div.legend span.badge-today {
  background-color: #357ebd;
  color: #ffffff;
  text-shadow: none; }

div.zabuto_calendar .badge-event,
div.zabuto_calendar div.legend span.badge-event {
  background-color: #ff9b08;
  color: #ffffff;
  text-shadow: none; }

div.zabuto_calendar .badge-event {
  font-size: 0.95em;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4px; }

/* legend */
div.zabuto_calendar div.legend {
  margin-top: 5px;
  text-align: right; }

div.zabuto_calendar div.legend span {
  color: #999999;
  font-size: 10px;
  font-weight: normal; }

div.zabuto_calendar div.legend span.legend-text:after,
div.zabuto_calendar div.legend span.legend-block:after,
div.zabuto_calendar div.legend span.legend-list:after,
div.zabuto_calendar div.legend span.legend-spacer:after {
  content: ' '; }

div.zabuto_calendar div.legend span.legend-spacer {
  padding-left: 25px; }

div.zabuto_calendar ul.legend > span {
  padding-left: 2px; }

div.zabuto_calendar ul.legend {
  display: inline-block;
  list-style: none outside none;
  margin: 0;
  padding: 0; }

div.zabuto_calendar ul.legend li {
  display: inline-block;
  height: 11px;
  width: 11px;
  margin-left: 5px; }

div.zabuto_calendar ul.legend div.zabuto_calendar ul.legend li:first-child {
  margin-left: 7px; }

div.zabuto_calendar ul.legend li:last-child {
  margin-right: 5px; }

div.zabuto_calendar div.legend span.badge {
  font-size: 0.9em;
  border-radius: 5px 5px 5px 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 3px; }

/* responsive */
@media (max-width: 979px) {
  div.zabuto_calendar .table th,
  div.zabuto_calendar .table td {
    padding: 2px 1px; } }

/*
 * 		Common Styles
 * 		-------------
 */
html,
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 34px;
  position: relative;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

body {
  background: #EAEEF1;
  color: #62666B; }
  @media only screen and (min-width: 768px) {
    body {
      display: table;
      width: 100%;
      min-height: 100vh; } }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

div,
p,
header,
nav,
aside,
section,
article {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

textarea,
input,
select,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1em; }
  textarea:focus,
  input:focus,
  select:focus,
  button:focus {
    outline: none; }

select::-ms-expand {
  display: none; }

a {
  color: #FF9100;
  text-decoration: none;
  border: none;
  transition: all ease-out .2s; }

.clearfix:after {
  content: "";
  display: block;
  clear: both; }

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

@font-face {
  font-family: 'icomoon';
  src: url("/fonts/icomoon.eot");
  src: url("/fonts/icomoon.eot") format("embedded-opentype"), url("/fonts/icomoon.ttf") format("truetype"), url("/fonts/icomoon.woff") format("woff"), url("/fonts/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-burger:before {
  content: "\e900"; }

.icon-chevron-bottom:before {
  content: "\e901"; }

.icon-chevron-left:before {
  content: "\e902"; }

.icon-chevron-right:before {
  content: "\e903"; }

.icon-close:before {
  content: "\e904"; }

.icon-file-empty:before {
  content: "\e924"; }

.icon-error:before {
  content: "\ea0f"; }

.icon-success:before {
  content: "\ea10"; }

.radio-option, .checkbox-option {
  *zoom: 1; }
  .radio-option:before, .checkbox-option:before, .radio-option:after, .checkbox-option:after {
    content: " ";
    display: table; }
  .radio-option:after, .checkbox-option:after {
    clear: both; }

.footer {
  background: #FFFFFF;
  padding: 20px 30px;
  font-size: 14px;
  line-height: 19px;
  color: #1D1D1B;
  text-align: center;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; }
  @media only screen and (min-width: 520px) {
    .footer {
      padding: 20px;
      text-align: left; } }
  @media only screen and (min-width: 1680px) {
    .footer {
      padding: 20px 50px; } }
  @media only screen and (min-width: 520px) {
    .footer__left {
      float: left; }
      .footer__left__element {
        display: inline-block; }
        .footer__left__element:first-child::after {
          content: ' - '; } }
  .footer__right {
    text-align: center; }
    @media only screen and (min-width: 520px) {
      .footer__right {
        float: right;
        text-align: right; } }

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  max-width: 320px;
  height: 100vh;
  background: #FFFFFF;
  z-index: 3;
  transform: translateX(-100%);
  transition: transform ease-out .4s, box-shadow ease-out .4s; }
  .header--open {
    transform: translateX(0);
    -webkit-box-shadow: 0px 2px 20px 0px rgba(8, 18, 30, 0.21);
    -moz-box-shadow: 0px 2px 20px 0px rgba(8, 18, 30, 0.21);
    box-shadow: 0px 2px 20px 0px rgba(8, 18, 30, 0.21); }
  @media only screen and (min-width: 1024px) {
    .header {
      bottom: inherit;
      width: 20%;
      height: auto;
      min-height: 100vh;
      transform: translateX(0);
      position: relative;
      display: table-cell;
      vertical-align: top;
      -webkit-box-shadow: 0px 2px 20px 0px rgba(8, 18, 30, 0.21);
      -moz-box-shadow: 0px 2px 20px 0px rgba(8, 18, 30, 0.21);
      box-shadow: 0px 2px 20px 0px rgba(8, 18, 30, 0.21); } }
  @media only screen and (min-width: 1680px) {
    .header {
      width: 300px; } }
  .header__closemenu {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    line-height: 20px;
    padding: 15px;
    color: #FF9100; }
    @media only screen and (min-width: 1024px) {
      .header__closemenu {
        display: none; } }
  .header__top {
    margin: 30px;
    text-align: center; }
  .header__bottom {
    text-align: center; }
    @media only screen and (min-width: 1024px) {
      .header__bottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        z-index: -1; } }
    .header__bottom__logo {
      max-width: 100%;
      padding: 0 40px; }

.socialLinks {
  margin-top: 20px;
  text-align: center; }
  @media only screen and (min-width: 1024px) {
    .socialLinks {
      display: none; } }
  .socialLinks .portal-icon {
    width: 100%;
    max-width: 100px; }
  .socialLinks .socialLinks-links {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px; }
    .socialLinks .socialLinks-links .header-icon {
      margin: 0 5px; }
      .socialLinks .socialLinks-links .header-icon img {
        vertical-align: middle;
        max-width: 14px; }

.menu {
  margin-bottom: 30px;
  background: #FFFFFF; }
  @media only screen and (min-width: 1024px) {
    .menu {
      margin-bottom: 163px; } }
  .menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: #D7DEE5 solid 1px; }
  .menu__firstlevel {
    border-top: #D7DEE5 solid 1px; }
    .menu__firstlevel__link {
      position: relative;
      display: block;
      padding: 15px 45px 15px 27px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      text-decoration: none;
      color: #62666B;
      border-left: 3px solid #FFFFFF;
      transition: border-color ease-out .2s; }
      .menu__firstlevel__link > .icon-chevron-right {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 12px;
        line-height: 12px;
        color: #173C68;
        transition: transform ease-out .2s; }
    .menu__firstlevel > ul {
      padding: 0 30px;
      background: #EAEEF1;
      overflow: hidden; }
    .menu__firstlevel--active .menu__firstlevel__link {
      border-color: #FF9100; }
      .menu__firstlevel--active .menu__firstlevel__link > .icon-chevron-right {
        transform: translateY(-50%) rotate(90deg); }
    .menu__firstlevel--active > ul {
      display: block; }
  .menu__secondlevel__link {
    position: relative;
    padding-top: 15px;
    display: block;
    font-size: 16px;
    line-height: 22px;
    color: #62666B;
    text-decoration: none; }
    .menu__secondlevel__link > .icon-chevron-right {
      position: absolute;
      right: 0;
      top: calc(50% + 7.5px);
      transform: translateY(-50%);
      font-size: 12px;
      line-height: 12px;
      color: #173C68;
      transition: transform ease-out .2s; }
  .menu__secondlevel ul {
    display: none;
    margin-top: 10px;
    border-left: 2px solid #FF9100; }
  .menu__secondlevel--active .menu__secondlevel__link {
    color: #FF9100; }
    .menu__secondlevel--active .menu__secondlevel__link > .icon-chevron-right {
      transform: translateY(-50%) rotate(90deg); }
  .menu__secondlevel--active > ul {
    display: block; }
  .menu__secondlevel:last-child {
    padding-bottom: 15px; }
  .menu__thirdlevel__link {
    padding: 5px 0 5px 20px;
    display: block;
    font-size: 14px;
    line-height: 22px;
    color: #62666B;
    text-decoration: none; }
  .menu__thirdlevel--active .menu__thirdlevel__link {
    color: #FF9100; }

.header__layout {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5); }

.main {
  width: 100%;
  float: left;
  z-index: 0;
  transition: width ease-out .4s;
  padding-bottom: 78px;
  position: relative;
  min-height: 100vh; }
  @media only screen and (min-width: 768px) {
    .main {
      padding-bottom: 59px; } }
  @media only screen and (min-width: 1024px) {
    .main {
      position: relative;
      display: table-cell;
      vertical-align: top;
      width: 80%;
      float: inherit; } }
  @media only screen and (min-width: 1680px) {
    .main {
      width: calc(100% - 300px); } }

.toolbar {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white; }
  .toolbar__burger {
    width: 50px;
    text-align: center;
    font-size: 14px;
    line-height: 40px;
    cursor: pointer; }
    @media only screen and (min-width: 1024px) {
      .toolbar__burger {
        display: none; } }
  .toolbar__title {
    width: calc(100% - 90px);
    font-size: 16px;
    line-height: 20px;
    padding: 10px 20px;
    margin: 0;
    font-family: "Bree Serif", sans-serif;
    color: #62666B;
    font-weight: 400; }
    @media only screen and (min-width: 1024px) {
      .toolbar__title {
        width: calc(100% - 75px);
        font-size: 30px;
        line-height: 35px;
        padding: 20px; } }
  .toolbar__searchButton {
    width: 40px;
    height: 40px;
    background: #173C68 url("/img/site/search-icon.svg") no-repeat center center/16px;
    cursor: pointer; }
    @media only screen and (min-width: 1024px) {
      .toolbar__searchButton {
        width: 75px;
        height: 75px;
        background-size: 25px; } }
  .toolbar__socialLinks {
    display: none;
    align-items: center;
    padding: 0 20px; }
    @media only screen and (min-width: 1024px) {
      .toolbar__socialLinks {
        display: flex; } }
    .toolbar__socialLinks .toobar-icon {
      margin: 0 5px; }
      .toolbar__socialLinks .toobar-icon img {
        vertical-align: middle;
        max-width: 30px; }
      .toolbar__socialLinks .toobar-icon:first-child img {
        max-width: 160px; }

.button {
  position: relative;
  color: white !important;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  background-color: #173C68;
  border-radius: 63px;
  border: none;
  padding: 10px;
  display: block;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  transition: 0.2s; }
  .button:hover {
    opacity: 0.85; }
  @media only screen and (min-width: 768px) {
    .button {
      padding: 10px 20px; } }
  @media only screen and (min-width: 1680px) {
    .button {
      font-size: 16px;
      line-height: 22px;
      padding: 20px 50px; } }
  .button--error {
    font-size: 18px;
    background-color: #B32B26 !important; }
    .button--error span {
      display: none; }
    .button--error i.icon-error {
      display: inline; }
    .button--error i.icon-success {
      display: none; }
  .button--success {
    font-size: 18px;
    background-color: #00B007 !important; }
    .button--success span {
      display: none; }
    .button--success i.icon-error {
      display: none; }
    .button--success i.icon-success {
      display: inline; }
  .button--submit i {
    display: none; }

.alert {
  background: #EA0000;
  color: #FFFFFF;
  padding: 20px 30px; }
  @media only screen and (min-width: 768px) {
    .alert {
      text-align: center; } }
  .alert__message {
    padding-left: 63px;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: .87px;
    background: url("/img/site/warning-icon.svg") no-repeat left center/40px; }
    @media only screen and (min-width: 768px) {
      .alert__message {
        font-size: 18px;
        line-height: 24px;
        padding-left: 40px; } }
    @media only screen and (min-width: 1024px) {
      .alert__message {
        font-size: 20px;
        line-height: 34px;
        padding-left: 63px; } }

.input-wrapper {
  margin-bottom: 20px; }
  @media only screen and (min-width: 1024px) {
    .input-wrapper {
      margin-bottom: 30px; } }
  @media only screen and (min-width: 1680px) {
    .input-wrapper {
      margin-bottom: 40px; } }

.input-wrapper.input-error {
  color: #B32B26; }
  .input-wrapper.input-error .input, .input-wrapper.input-error .textarea, .input-wrapper.input-error .select {
    border-color: #B32B26;
    background-color: #f5e2e2; }
  .input-wrapper.input-error .selectType:after {
    background-color: #f5e2e2; }

.form-label {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 10px; }
  .form-label-required {
    color: #B32B26;
    font-weight: 700; }
  @media only screen and (min-width: 1024px) {
    .form-label {
      line-height: 20px; } }
  @media only screen and (min-width: 1680px) {
    .form-label {
      font-size: 20px;
      margin-bottom: 20px; } }

.input, .textarea, .select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  line-height: 18px;
  background: #EAEEF1;
  border: 1px solid #D7DEE5;
  border-radius: 5px;
  color: #3B434C;
  font-weight: 300; }
  @media only screen and (min-width: 1024px) {
    .input, .textarea, .select {
      padding: 8px 12px;
      font-size: 16px;
      line-height: 24px; } }
  @media only screen and (min-width: 1680px) {
    .input, .textarea, .select {
      padding: 12px 17px;
      font-size: 20px;
      line-height: 27px; } }

.textarea {
  height: 150px;
  resize: vertical; }
  @media only screen and (min-width: 1024px) {
    .textarea {
      height: 200px; } }

.select {
  padding-right: 50px; }
  @media only screen and (min-width: 1024px) {
    .select {
      padding-right: 54px; } }
  @media only screen and (min-width: 1680px) {
    .select {
      padding-right: 70px; } }

.selectType {
  position: relative; }
  .selectType:after {
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background: #173C68 url("/img/site/chevron-down.svg") no-repeat center center/12px;
    pointer-events: none; }
    @media only screen and (min-width: 1024px) {
      .selectType:after {
        width: 42px;
        height: 42px; } }
    @media only screen and (min-width: 1680px) {
      .selectType:after {
        width: 53px;
        height: 53px; } }

.radio-option {
  margin-bottom: 10px;
  cursor: pointer; }
  .radio-option > input {
    display: none; }
  .radio-option .radio {
    float: left;
    width: 20px;
    height: 20px;
    background-color: #EAEEF1;
    border: 1px solid #D7DEE5;
    border-radius: 30px;
    background-position: center center;
    transition: background ease-out .2s, border ease-out .2s; }
    @media only screen and (min-width: 768px) {
      .radio-option .radio {
        width: 30px;
        height: 30px; } }
    .radio-option .radio.checked {
      background: #173C68 url("/img/site/radio-check.svg") no-repeat center center/8px;
      border: none; }
      @media only screen and (min-width: 768px) {
        .radio-option .radio.checked {
          background-size: 12px; } }
  .radio-option > label {
    float: left;
    width: calc(100% - 20px);
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px; }
    @media only screen and (min-width: 768px) {
      .radio-option > label {
        width: calc(100% - 30px);
        font-size: 16px;
        line-height: 30px;
        padding-left: 15px; } }
    @media only screen and (min-width: 1680px) {
      .radio-option > label {
        font-size: 20px; } }

.checkbox-option {
  margin-bottom: 10px;
  cursor: pointer; }
  .checkbox-option > input {
    display: none; }
  .checkbox-option .checkbox {
    float: left;
    width: 20px;
    height: 20px;
    background-color: #EAEEF1;
    border: 1px solid #D7DEE5;
    background-position: center center;
    transition: background ease-out .2s, border ease-out .2s; }
    @media only screen and (min-width: 768px) {
      .checkbox-option .checkbox {
        width: 30px;
        height: 30px; } }
    .checkbox-option .checkbox.checked {
      background: #173C68 url("/img/site/checkbox-check.svg") no-repeat center center/12px;
      border: none; }
      @media only screen and (min-width: 768px) {
        .checkbox-option .checkbox.checked {
          background-size: 17px; } }
  .checkbox-option > label {
    float: left;
    width: calc(100% - 20px);
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px; }
    @media only screen and (min-width: 768px) {
      .checkbox-option > label {
        width: calc(100% - 30px);
        font-size: 16px;
        line-height: 30px;
        padding-left: 15px; } }
    @media only screen and (min-width: 1680px) {
      .checkbox-option > label {
        font-size: 20px; } }

.search__layout {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5); }

.search__module {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9); }
  @media only screen and (min-width: 1024px) {
    .search__module {
      padding: 30px; } }
  .search__module > form {
    overflow: hidden; }
    .search__module > form .input-box {
      float: left;
      width: calc(100% - 108px); }
      @media only screen and (min-width: 768px) {
        .search__module > form .input-box {
          width: calc(100% - 140px); } }
      @media only screen and (min-width: 1024px) {
        .search__module > form .input-box {
          width: calc(100% - 180px); } }
      .search__module > form .input-box .twitter-typeahead {
        width: 100%; }
      .search__module > form .input-box .tt-menu {
        position: inherit !important;
        top: inherit !important;
        left: inherit !important;
        z-index: inherit !important; }
        .search__module > form .input-box .tt-menu .tt-selectable {
          padding: 5px 10px;
          opacity: .5; }
          .search__module > form .input-box .tt-menu .tt-selectable:hover {
            opacity: 1; }
          .search__module > form .input-box .tt-menu .tt-selectable.tt-cursor {
            opacity: 1; }
          .search__module > form .input-box .tt-menu .tt-selectable .tt-highlight {
            color: #FF9100; }
      .search__module > form .input-box input[type=search] {
        width: 100%;
        border: none;
        background: none;
        font-size: 20px;
        line-height: 32px; }
        @media only screen and (min-width: 768px) {
          .search__module > form .input-box input[type=search] {
            font-size: 30px;
            line-height: 50px;
            width: calc(100% - 140px); } }
        @media only screen and (min-width: 1024px) {
          .search__module > form .input-box input[type=search] {
            font-size: 45px;
            line-height: 82px;
            width: calc(100% - 180px); } }
        .search__module > form .input-box input[type=search]::-ms-clear {
          display: none; }
        .search__module > form .input-box input[type=search].tt-hint {
          opacity: .3 !important;
          background: none !important; }
    .search__module > form > button[type=submit] {
      float: left;
      width: 34px;
      height: 34px;
      padding: 0;
      border-radius: 50%;
      background: #FF9100 url("/img/site/right-arrow.svg") no-repeat center center;
      background-size: 20px;
      border: 2px solid #FF9100;
      margin: 0 20px;
      cursor: pointer; }
      @media only screen and (min-width: 768px) {
        .search__module > form > button[type=submit] {
          width: 50px;
          height: 50px;
          margin: 0 20px;
          background-size: auto; } }
      @media only screen and (min-width: 1024px) {
        .search__module > form > button[type=submit] {
          width: 60px;
          height: 60px;
          margin: 12px 30px 0; } }
  .search__module__close {
    float: left;
    width: 34px;
    height: 34px;
    padding: 0;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid #FF9100;
    text-align: center;
    cursor: pointer;
    line-height: 30px; }
    @media only screen and (min-width: 768px) {
      .search__module__close {
        width: 50px;
        height: 50px;
        line-height: 46px; } }
    @media only screen and (min-width: 1024px) {
      .search__module__close {
        width: 60px;
        height: 60px;
        margin-top: 12px;
        line-height: 56px; } }
    .search__module__close > i {
      color: #FF9100;
      font-size: 14px; }
      @media only screen and (min-width: 768px) {
        .search__module__close > i {
          font-size: 20px; } }

.dashboard {
  overflow: hidden; }
  .dashboard__articles {
    overflow: hidden;
    float: left;
    width: 100%;
    height: 320px; }
    @media only screen and (min-width: 768px) {
      .dashboard__articles {
        width: calc(100% - 450px);
        min-width: 60%;
        height: 450px; } }
    @media only screen and (min-width: 1680px) {
      .dashboard__articles {
        height: 635px; } }
  .dashboard__article {
    position: relative;
    width: 100%;
    height: 320px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media only screen and (min-width: 768px) {
      .dashboard__article {
        height: 450px; } }
    @media only screen and (min-width: 1680px) {
      .dashboard__article {
        height: 635px; } }
    .dashboard__article__layout {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(255, 255, 255, 0);
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.33) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(0, 0, 0, 0.33)));
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.33) 100%);
      background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.33) 100%);
      background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.33) 100%);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.33) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 ); }
    .dashboard__article__block {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 20px; }
      @media only screen and (min-width: 1024px) {
        .dashboard__article__block {
          margin: 30px 40px; } }
      .dashboard__article__block > h2 {
        width: calc(100% - 55px);
        color: #FFFFFF;
        font-size: 16px;
        line-height: 22px;
        margin: 0 0 10px;
        font-weight: 700;
        letter-spacing: .53px;
        text-shadow: 0 0 5px #323232; }
        .dashboard__article__block > h2 > a {
          color: #FFFFFF; }
        @media only screen and (min-width: 1024px) {
          .dashboard__article__block > h2 {
            width: calc(100% - 70px);
            font-size: 20px;
            line-height: 24px;
            letter-spacing: .85px;
            margin: 0 0 10px; } }
        @media only screen and (min-width: 1680px) {
          .dashboard__article__block > h2 {
            width: calc(100% - 80px);
            font-size: 26px;
            line-height: 36px;
            letter-spacing: .85px;
            margin: 0 0 20px; } }
      .dashboard__article__block > p {
        width: calc(100% - 55px);
        color: #FFFFFF;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: .47px;
        text-shadow: 0 0 5px #646464; }
        .dashboard__article__block > p > a {
          margin-top: 10px;
          color: #FFFFFF; }
        @media only screen and (min-width: 1024px) {
          .dashboard__article__block > p {
            width: calc(100% - 70px);
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 1px; } }
        @media only screen and (min-width: 1680px) {
          .dashboard__article__block > p {
            width: calc(100% - 80px);
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 1px; } }
        .dashboard__article__block > p > a {
          display: block; }
          .dashboard__article__block > p > a:hover {
            opacity: 0.85; }
          @media only screen and (min-width: 1024px) {
            .dashboard__article__block > p > a {
              display: inline-block; } }
      .dashboard__article__block__link {
        position: absolute;
        width: 40px;
        height: 40px;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        background: #FF9100 url("/img/site/plus-icon.svg") no-repeat center center/14px; }
        .dashboard__article__block__link:hover {
          opacity: 0.85; }
        @media only screen and (min-width: 1024px) {
          .dashboard__article__block__link {
            width: 50px;
            height: 50px;
            background-size: 18px; } }
        @media only screen and (min-width: 1680px) {
          .dashboard__article__block__link {
            width: 60px;
            height: 60px;
            background-size: 20px; } }
  .dashboard .flickity-prev-next-button.previous:disabled {
    opacity: 0; }
  .dashboard .flickity-prev-next-button.next:disabled {
    opacity: 0; }

.calendar {
  position: relative;
  float: left;
  width: 100%;
  color: #FFFFFF; }
  @media only screen and (min-width: 768px) {
    .calendar {
      height: 450px;
      width: 450px;
      max-width: 40%; } }
  @media only screen and (min-width: 1680px) {
    .calendar {
      height: 635px; } }
  .calendar__gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #173C68 !important;
    background: -moz-linear-gradient(-45deg, #173C68 0%, #FF9100 100%) !important;
    background: -webkit-gradient(left top, right bottom, color-stop(0%, #173C68), color-stop(100%, #FF9100)) !important;
    background: -webkit-linear-gradient(-45deg, #173C68 0%, #FF9100 100%) !important;
    background: -o-linear-gradient(-45deg, #173C68 0%, #FF9100 100%) !important;
    background: -ms-linear-gradient(-45deg, #173C68 0%, #FF9100 100%) !important;
    background: linear-gradient(135deg, #173C68 0%, #FF9100 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$defaultColorStart', endColorstr='$defaultColorEnd', GradientType=1) !important;
    z-index: -2; }
  .calendar__layout {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(29, 29, 27, 0.5);
    z-index: -1; }
  .calendar__nocalendar {
    width: 100%;
    padding: 20px; }
    @media only screen and (min-width: 768px) {
      .calendar__nocalendar {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }
    @media only screen and (min-width: 1680px) {
      .calendar__nocalendar {
        width: calc(100% - 60px); } }
    .calendar__nocalendar .icon-calendar {
      width: 80px;
      height: 80px;
      margin: 0 auto 50px;
      background: url("/img/site/calendar-icon.svg") no-repeat center center/contain; }
  .calendar__wrapper {
    width: 100%;
    padding: 20px; }
    @media only screen and (min-width: 1680px) {
      .calendar__wrapper {
        padding: 30px; } }
    .calendar__wrapper .flickity-viewport {
      position: relative;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch; }
  .calendar__buttons {
    overflow: hidden;
    margin-bottom: 20px; }
    @media only screen and (min-width: 1680px) {
      .calendar__buttons {
        margin-bottom: 40px; } }
    .calendar__buttons__button {
      float: left;
      width: 50%;
      text-align: center;
      font-size: 11px;
      line-height: 13px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #FFFFFF;
      transition: background ease-out .2s, color ease-out .2s;
      cursor: pointer; }
      @media only screen and (min-width: 768px) {
        .calendar__buttons__button {
          width: 50%; } }
      @media only screen and (min-width: 1680px) {
        .calendar__buttons__button {
          font-size: 16px;
          line-height: 22px;
          padding: 15px 10px; } }
      .calendar__buttons__button:nth-child(1) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .calendar__buttons__button:nth-child(2) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .calendar__buttons__button--active {
        background: #FFFFFF;
        color: #62666B; }
  .calendar__calendar-view {
    width: 100%; }
  .calendar__events {
    width: 100%; }
    .calendar__events .event {
      overflow: hidden;
      margin-bottom: 15px; }
      @media only screen and (min-width: 1680px) {
        .calendar__events .event {
          margin-bottom: 30px; } }
      .calendar__events .event__date {
        float: left;
        width: 45px;
        height: 45px;
        border-radius: 60px;
        border: 1px solid #FFFFFF;
        margin-right: 20px;
        text-align: center;
        padding: 5px; }
        @media only screen and (min-width: 1680px) {
          .calendar__events .event__date {
            width: 60px;
            height: 60px;
            border: 2px solid #FFFFFF; } }
        .calendar__events .event__date .day {
          font-size: 18px;
          line-height: 18px; }
          @media only screen and (min-width: 1680px) {
            .calendar__events .event__date .day {
              font-size: 26px;
              line-height: 28px; } }
        .calendar__events .event__date .month {
          font-size: 10px;
          line-height: 10px;
          font-weight: 300; }
          @media only screen and (min-width: 1680px) {
            .calendar__events .event__date .month {
              font-size: 14px;
              line-height: 14px; } }
      .calendar__events .event__infos {
        float: left;
        width: calc(100% - 80px); }
        .calendar__events .event__infos .name {
          font-size: 12px;
          line-height: 14px;
          max-height: 36px;
          overflow-y: hidden;
          font-weight: 300;
          margin-bottom: 5px;
          color: #FFF; }
          @media only screen and (min-width: 1680px) {
            .calendar__events .event__infos .name {
              font-size: 16px;
              line-height: 22px;
              max-height: 44px;
              margin-bottom: 10px; } }
        .calendar__events .event__infos .link {
          display: block;
          color: #FFF;
          font-size: 10px;
          font-weight: 300;
          line-height: 12px; }
          .calendar__events .event__infos .link:hover {
            opacity: 0.85;
            text-decoration: underline; }
          @media only screen and (min-width: 1680px) {
            .calendar__events .event__infos .link {
              font-size: 12px;
              line-height: 14px; } }
    @media only screen and (min-width: 1680px) {
      .calendar__events .button {
        margin-top: 40px; } }
  .calendar__legend {
    display: table;
    font-family: "Open Sans", sans-serif;
    font-size: 8px;
    line-height: 10px;
    margin: 10px 0 15px 0; }
    @media only screen and (min-width: 1680px) {
      .calendar__legend {
        font-size: 10px;
        line-height: 13px;
        margin: 15px 0 20px 0; } }
    .calendar__legend div {
      display: table-cell;
      padding: 0 6px 0 20px;
      vertical-align: middle; }
      .calendar__legend div:last-child {
        padding-right: 0; }
      .calendar__legend div.event_holiday {
        position: relative; }
        .calendar__legend div.event_holiday::after {
          content: '';
          position: absolute;
          z-index: -1;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 14px;
          height: 14px;
          background-size: 14px 14px;
          background-image: url("/img/site/event-holiday-legend.svg"); }
      .calendar__legend div.event_pedago1 {
        position: relative; }
        .calendar__legend div.event_pedago1::after {
          content: '';
          position: absolute;
          z-index: -1;
          top: 50%;
          left: 0;
          width: 14px;
          height: 14px;
          transform: translateY(-50%);
          background-size: 14px 14px;
          background-image: url("/img/site/event-pedago1-legend.svg"); }
      .calendar__legend div.event_pedago2 {
        position: relative; }
        .calendar__legend div.event_pedago2::after {
          content: '';
          position: absolute;
          z-index: -1;
          top: 50%;
          left: 0;
          width: 14px;
          height: 14px;
          transform: translateY(-50%);
          background-size: 14px 14px;
          background-image: url("/img/site/event-pedago2-legend.svg"); }
      .calendar__legend div.event_pedago3 {
        position: relative; }
        .calendar__legend div.event_pedago3::after {
          content: '';
          position: absolute;
          z-index: -1;
          top: 50%;
          left: 0;
          width: 14px;
          height: 14px;
          transform: translateY(-50%);
          background-size: 14px 14px;
          background-image: url("/img/site/event-pedago3-legend.svg"); }

div.zabuto_calendar .table tr th,
div.zabuto_calendar .table tr td,
div.zabuto_calendar .table tr.calendar-month-header th,
div.zabuto_calendar .table tr.calendar-dow-header th,
div.zabuto_calendar .table tr.calendar-month-header th span,
div.zabuto_calendar div.calendar-month-navigation,
div.zabuto_calendar .badge-today, div.zabuto_calendar div.legend span.badge-today {
  background: none;
  padding: 0; }

div.zabuto_calendar {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 34px; }

div.zabuto_calendar .table {
  line-height: 24px; }
  div.zabuto_calendar .table tr.calendar-month-header {
    line-height: 12px; }
    div.zabuto_calendar .table tr.calendar-month-header th {
      font-size: 12px;
      padding-bottom: 10px; }
      @media only screen and (min-width: 1680px) {
        div.zabuto_calendar .table tr.calendar-month-header th {
          font-size: 16px; } }
      div.zabuto_calendar .table tr.calendar-month-header th:nth-child(2) {
        text-transform: uppercase;
        font-weight: 300; }
  div.zabuto_calendar .table tr.calendar-dow-header th {
    color: #FFFFFF;
    font-size: 12px;
    padding-bottom: 5px; }
    @media only screen and (min-width: 1680px) {
      div.zabuto_calendar .table tr.calendar-dow-header th {
        font-size: 14px;
        padding-bottom: 15px; } }
  div.zabuto_calendar .table tr.calendar-dow td {
    padding-bottom: 5px;
    font-size: 12px; }
    @media only screen and (min-width: 1680px) {
      div.zabuto_calendar .table tr.calendar-dow td {
        font-size: 14px;
        padding-bottom: 15px; } }
    div.zabuto_calendar .table tr.calendar-dow td .day {
      padding: 2px; }
      div.zabuto_calendar .table tr.calendar-dow td .day .badge.badge-today {
        position: relative;
        font-size: 20px;
        font-weight: 600; }
      div.zabuto_calendar .table tr.calendar-dow td .day .badge.badge-event {
        padding: 0;
        text-align: center;
        border-radius: 50%;
        background-color: white;
        color: #FF9100;
        width: 30px;
        height: 30px;
        line-height: 30px; }
      div.zabuto_calendar .table tr.calendar-dow td .day.past {
        opacity: .5; }
      div.zabuto_calendar .table tr.calendar-dow td .day.event_holiday {
        position: relative; }
        div.zabuto_calendar .table tr.calendar-dow td .day.event_holiday::after {
          content: '';
          position: absolute;
          z-index: -1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 28px;
          height: 28px;
          background-size: 28px 28px;
          background-image: url("/img/site/event-holiday.svg"); }
          @media only screen and (min-width: 1680px) {
            div.zabuto_calendar .table tr.calendar-dow td .day.event_holiday::after {
              width: 34px;
              height: 34px;
              background-size: 34px 34px; } }
        div.zabuto_calendar .table tr.calendar-dow td .day.event_holiday.today::after {
          background-image: url("/img/site/event-holiday-fill.svg"); }
        div.zabuto_calendar .table tr.calendar-dow td .day.event_holiday.today .badge.badge-today {
          color: #3B434C;
          font-size: inherit; }
      div.zabuto_calendar .table tr.calendar-dow td .day.event_pedago1 {
        position: relative;
        margin-top: 4px;
        margin-bottom: -4px; }
        div.zabuto_calendar .table tr.calendar-dow td .day.event_pedago1::after {
          content: '';
          position: absolute;
          z-index: -1;
          top: 34%;
          left: 50%;
          width: 36px;
          height: 28px;
          transform: translate(-50%, -50%);
          background-size: 36px 28px;
          background-image: url("/img/site/event-pedago1.svg"); }
          @media only screen and (min-width: 1680px) {
            div.zabuto_calendar .table tr.calendar-dow td .day.event_pedago1::after {
              width: 42px;
              height: 34px;
              background-size: 42px 34px; } }
        div.zabuto_calendar .table tr.calendar-dow td .day.event_pedago1.today::after {
          background-image: url("/img/site/event-pedago1-fill.svg"); }
        div.zabuto_calendar .table tr.calendar-dow td .day.event_pedago1.today .badge.badge-today {
          color: #3B434C;
          font-size: inherit; }
      div.zabuto_calendar .table tr.calendar-dow td .day.event_custom {
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center; }
      div.zabuto_calendar .table tr.calendar-dow td .day.event_pedago2 {
        position: relative;
        margin-top: -4px;
        margin-bottom: 4px; }
        div.zabuto_calendar .table tr.calendar-dow td .day.event_pedago2::after {
          content: '';
          position: absolute;
          z-index: -1;
          top: 66%;
          left: 50%;
          width: 36px;
          height: 28px;
          transform: translate(-50%, -50%);
          background-size: 36px 28px;
          background-image: url("/img/site/event-pedago2.svg"); }
          @media only screen and (min-width: 1680px) {
            div.zabuto_calendar .table tr.calendar-dow td .day.event_pedago2::after {
              width: 42px;
              height: 34px;
              background-size: 42px 34px; } }
        div.zabuto_calendar .table tr.calendar-dow td .day.event_pedago2.today::after {
          background-image: url("/img/site/event-pedago2-fill.svg"); }
        div.zabuto_calendar .table tr.calendar-dow td .day.event_pedago2.today .badge.badge-today {
          color: #3B434C;
          font-size: inherit; }
      div.zabuto_calendar .table tr.calendar-dow td .day.event_pedago3 {
        position: relative; }
        div.zabuto_calendar .table tr.calendar-dow td .day.event_pedago3::after {
          content: '';
          position: absolute;
          z-index: -1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 28px;
          height: 28px;
          background-size: 28px 28px;
          background-image: url("/img/site/event-pedago3.svg"); }
          @media only screen and (min-width: 1680px) {
            div.zabuto_calendar .table tr.calendar-dow td .day.event_pedago3::after {
              width: 34px;
              height: 34px;
              background-size: 34px 34px; } }
        div.zabuto_calendar .table tr.calendar-dow td .day.event_pedago3.today::after {
          background-image: url("/img/site/event-pedago3-fill.svg"); }
        div.zabuto_calendar .table tr.calendar-dow td .day.event_pedago3.today .badge.badge-today {
          color: #3B434C;
          font-size: inherit;
          text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF; }

div.zabuto_calendar .legend {
  display: none; }

.glyphicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .glyphicon.glyphicon-chevron-left:before {
    content: "\e902"; }
  .glyphicon.glyphicon-chevron-right:before {
    content: "\e903"; }

.publication__header {
  position: relative;
  height: 320px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  @media only screen and (min-width: 768px) {
    .publication__header {
      height: 450px; } }
  @media only screen and (min-width: 1680px) {
    .publication__header {
      height: 634px; } }
  .publication__header--noimage {
    height: 175px;
    background-color: #173C68; }
    @media only screen and (min-width: 768px) {
      .publication__header--noimage {
        height: 233px; } }
    @media only screen and (min-width: 1680px) {
      .publication__header--noimage {
        height: 313px; } }
  .publication__header #map, .publication__header--map {
    height: 175px; }
    @media only screen and (min-width: 768px) {
      .publication__header #map, .publication__header--map {
        height: 233px; } }
    @media only screen and (min-width: 1680px) {
      .publication__header #map, .publication__header--map {
        height: 313px; } }
  .publication__header__layout {
    position: absolute;
    top: 25%;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0);
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(0, 0, 0, 0.75)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 ); }
  .publication__header__address {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    margin: 20px;
    color: #FFFFFF; }
    @media only screen and (min-width: 768px) {
      .publication__header__address {
        margin: 30px 30px 40px; } }
    @media only screen and (min-width: 1680px) {
      .publication__header__address {
        margin: 30px 30px 60px; } }
    .publication__header__address::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 90px;
      height: 2px;
      background-color: #FF9100; }
      @media only screen and (min-width: 768px) {
        .publication__header__address::after {
          height: 4px;
          width: 120px; } }
      @media only screen and (min-width: 1024px) {
        .publication__header__address::after {
          height: 6px; } }
    .publication__header__address__title {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: .47px;
      max-height: 57px;
      overflow-y: hidden;
      font-weight: normal;
      margin: 0 0 20px; }
      .publication__header__address__title a {
        color: #FFFFFF; }
      @media only screen and (min-width: 768px) {
        .publication__header__address__title {
          font-size: 24px;
          line-height: 32px;
          letter-spacing: .8px;
          max-height: 57px;
          margin: 0 0 30px; } }
      @media only screen and (min-width: 1024px) {
        .publication__header__address__title {
          font-size: 22px;
          line-height: 30px;
          letter-spacing: 1px;
          max-height: 80px; } }
      @media only screen and (min-width: 1680px) {
        .publication__header__address__title {
          font-size: 30px;
          line-height: 37px;
          letter-spacing: 1.33px;
          max-height: 94px;
          margin: 0 0 26px; } }
  .publication__header__block {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    margin: 20px;
    color: #FFFFFF; }
    @media only screen and (min-width: 768px) {
      .publication__header__block {
        margin: 30px 30px 40px; } }
    @media only screen and (min-width: 1680px) {
      .publication__header__block {
        margin: 30px 30px 60px; } }
    .publication__header__block::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 90px;
      height: 2px;
      background-color: #FF9100; }
      @media only screen and (min-width: 768px) {
        .publication__header__block::after {
          height: 4px;
          width: 120px; } }
      @media only screen and (min-width: 1024px) {
        .publication__header__block::after {
          height: 6px; } }
    .publication__header__block__title {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: .47px;
      max-height: 57px;
      overflow-y: hidden;
      font-weight: normal;
      margin: 0 0 20px; }
      @media only screen and (min-width: 768px) {
        .publication__header__block__title {
          font-size: 24px;
          line-height: 32px;
          letter-spacing: .8px;
          max-height: 57px;
          margin: 0 0 30px; } }
      @media only screen and (min-width: 1024px) {
        .publication__header__block__title {
          font-size: 32px;
          line-height: 40px;
          letter-spacing: 1px;
          max-height: 80px; } }
      @media only screen and (min-width: 1680px) {
        .publication__header__block__title {
          font-size: 40px;
          line-height: 47px;
          letter-spacing: 1.33px;
          max-height: 94px;
          margin: 0 0 46px; } }
    .publication__header__block__subtitle {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: .53px;
      font-weight: normal;
      margin: 0 0 30px; }
      @media only screen and (min-width: 768px) {
        .publication__header__block__subtitle {
          font-size: 18px;
          line-height: 24px;
          letter-spacing: .70px; } }
      @media only screen and (min-width: 1024px) {
        .publication__header__block__subtitle {
          font-size: 22px;
          line-height: 28px;
          letter-spacing: .80px; } }
      @media only screen and (min-width: 1680px) {
        .publication__header__block__subtitle {
          font-size: 26px;
          line-height: 36px;
          letter-spacing: .87px; } }

.publication__breadcrumb {
  text-align: center;
  padding: 10px 30px;
  font-size: 12px;
  line-height: 16px; }
  @media only screen and (min-width: 768px) {
    .publication__breadcrumb {
      font-size: 14px;
      line-height: 28px; } }
  @media only screen and (min-width: 1024px) {
    .publication__breadcrumb {
      font-size: 16px;
      line-height: 34px; } }
  .publication__breadcrumb__link {
    color: #62666B; }
    .publication__breadcrumb__link::after {
      content: ' >'; }
    .publication__breadcrumb__link:hover {
      opacity: 0.85; }
    .publication__breadcrumb__link--active {
      color: #FF9100;
      font-weight: 600; }
      .publication__breadcrumb__link--active::after {
        content: ''; }

.publication__wrapper {
  background: #FFFFFF;
  padding: 20px 0; }
  @media only screen and (min-width: 768px) {
    .publication__wrapper {
      padding: 20px; } }
  @media only screen and (min-width: 1024px) {
    .publication__wrapper {
      padding: 40px; } }
  @media only screen and (min-width: 1680px) {
    .publication__wrapper {
      padding: 60px; } }
  .publication__wrapper__content {
    max-width: 1080px;
    margin: 0 auto; }
    .publication__wrapper__content .reading-title,
    .publication__wrapper__content .floating-text .reading-title .publication__wrapper__content .article_form .reading-title {
      font-size: 18px;
      line-height: 28px;
      margin: 0 0 10px;
      font-weight: 600;
      padding: 0 20px; }
      @media only screen and (min-width: 768px) {
        .publication__wrapper__content .reading-title,
        .publication__wrapper__content .floating-text .reading-title .publication__wrapper__content .article_form .reading-title {
          font-size: 20px;
          line-height: 28px;
          padding: 0; } }
      @media only screen and (min-width: 1024px) {
        .publication__wrapper__content .reading-title,
        .publication__wrapper__content .floating-text .reading-title .publication__wrapper__content .article_form .reading-title {
          margin-bottom: 20px; } }
      @media only screen and (min-width: 1680px) {
        .publication__wrapper__content .reading-title,
        .publication__wrapper__content .floating-text .reading-title .publication__wrapper__content .article_form .reading-title {
          font-size: 30px;
          line-height: 34px; } }
    .publication__wrapper__content > .reading-text {
      margin-bottom: 20px; }
      @media only screen and (min-width: 1024px) {
        .publication__wrapper__content > .reading-text {
          margin-bottom: 40px; } }
      @media only screen and (min-width: 1680px) {
        .publication__wrapper__content > .reading-text {
          margin-bottom: 50px; } }
    .publication__wrapper__content .reading-text,
    .publication__wrapper__content .floating-text .publication__wrapper__content .article_form .reading-text {
      font-size: 14px;
      line-height: 24px;
      font-weight: 300;
      padding: 0 20px; }
      @media only screen and (min-width: 768px) {
        .publication__wrapper__content .reading-text,
        .publication__wrapper__content .floating-text .publication__wrapper__content .article_form .reading-text {
          font-size: 16px;
          line-height: 28px;
          padding: 0; } }
      @media only screen and (min-width: 1680px) {
        .publication__wrapper__content .reading-text,
        .publication__wrapper__content .floating-text .publication__wrapper__content .article_form .reading-text {
          font-size: 20px;
          line-height: 34px; } }
      .publication__wrapper__content .reading-text p,
      .publication__wrapper__content .reading-text ul,
      .publication__wrapper__content .reading-text ol,
      .publication__wrapper__content .floating-text .publication__wrapper__content .article_form .reading-text p,
      .publication__wrapper__content .floating-text .publication__wrapper__content .article_form .reading-text ul,
      .publication__wrapper__content .floating-text .publication__wrapper__content .article_form .reading-text ol {
        margin-bottom: 15px; }
      .publication__wrapper__content .reading-text ul li,
      .publication__wrapper__content .floating-text .publication__wrapper__content .article_form .reading-text ul li {
        list-style-type: disc; }
      .publication__wrapper__content .reading-text ol li,
      .publication__wrapper__content .floating-text .publication__wrapper__content .article_form .reading-text ol li {
        list-style-type: decimal; }
      .publication__wrapper__content .reading-text a,
      .publication__wrapper__content .floating-text .publication__wrapper__content .article_form .reading-text a {
        text-decoration: underline; }
        .publication__wrapper__content .reading-text a:hover,
        .publication__wrapper__content .floating-text .publication__wrapper__content .article_form .reading-text a:hover {
          opacity: 0.85; }
      .publication__wrapper__content .reading-text table,
      .publication__wrapper__content .floating-text .publication__wrapper__content .article_form .reading-text table {
        display: table;
        width: 100%;
        border-width: 0;
        border-collapse: collapse;
        margin-top: 0;
        margin-bottom: 15px; }
        .publication__wrapper__content .reading-text table thead tr th,
        .publication__wrapper__content .floating-text .publication__wrapper__content .article_form .reading-text table thead tr th {
          font-weight: normal;
          background-color: #888;
          color: #fff;
          text-align: left;
          padding: 8px 0 8px 10px; }
        .publication__wrapper__content .reading-text table tr td,
        .publication__wrapper__content .floating-text .publication__wrapper__content .article_form .reading-text table tr td {
          border-bottom: 1px solid #ddd;
          padding: 8px 0 8px 10px; }
        .publication__wrapper__content .reading-text table tr:nth-child(even),
        .publication__wrapper__content .floating-text .publication__wrapper__content .article_form .reading-text table tr:nth-child(even) {
          background-color: #efefef; }
      .publication__wrapper__content .reading-text .block-table,
      .publication__wrapper__content .floating-text .publication__wrapper__content .article_form .reading-text .block-table {
        overflow-x: scroll; }
    .publication__wrapper__content > .floating-text {
      margin-bottom: 20px; }
      @media only screen and (min-width: 1024px) {
        .publication__wrapper__content > .floating-text {
          margin-bottom: 40px; } }
      @media only screen and (min-width: 1680px) {
        .publication__wrapper__content > .floating-text {
          margin-bottom: 50px; } }
      .publication__wrapper__content > .floating-text--left > img {
        width: 100%; }
        @media only screen and (min-width: 768px) {
          .publication__wrapper__content > .floating-text--left > img {
            float: left;
            width: calc(50% - 10px);
            margin: 0 20px 20px 0; } }
        @media only screen and (min-width: 1024px) {
          .publication__wrapper__content > .floating-text--left > img {
            width: calc(50% - 20px);
            margin: 0 40px 40px 0; } }
      .publication__wrapper__content > .floating-text--right > img {
        width: 100%; }
        @media only screen and (min-width: 768px) {
          .publication__wrapper__content > .floating-text--right > img {
            float: right;
            width: calc(50% - 20px);
            margin: 0 0 20px 20px; } }
        @media only screen and (min-width: 1024px) {
          .publication__wrapper__content > .floating-text--right > img {
            width: calc(50% - 20px);
            margin: 0 0 40px 40px; } }
    .publication__wrapper__content > .image {
      text-align: center;
      margin-bottom: 20px; }
      @media only screen and (min-width: 1024px) {
        .publication__wrapper__content > .image {
          margin-bottom: 40px; } }
      @media only screen and (min-width: 1680px) {
        .publication__wrapper__content > .image {
          margin-bottom: 50px; } }
      .publication__wrapper__content > .image img {
        max-width: 100%; }
      .publication__wrapper__content > .image .image__caption {
        margin-bottom: -12px;
        padding-right: 10px;
        font-size: 10px;
        line-height: 12px;
        text-align: center; }
        @media only screen and (min-width: 768px) {
          .publication__wrapper__content > .image .image__caption {
            padding-right: 0; } }

.publicationsList {
  padding: 30px 0; }
  @media only screen and (min-width: 768px) {
    .publicationsList {
      padding: 30px 20px; } }
  @media only screen and (min-width: 1024px) {
    .publicationsList {
      padding: 50px; } }
  .publicationsList__title {
    position: relative;
    margin-bottom: 30px;
    padding: 0 20px; }
    @media only screen and (min-width: 768px) {
      .publicationsList__title {
        padding: 0;
        overflow: hidden;
        background: url("/img/site/select-line.jpg") repeat-x center center; } }
    @media only screen and (min-width: 1024px) {
      .publicationsList__title {
        margin-bottom: 40px; } }
    .publicationsList__title > h3 {
      font-size: 20px;
      line-height: 27px;
      letter-spacing: .67px;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
      font-weight: normal;
      background: #EAEEF1;
      margin-bottom: 20px; }
      @media only screen and (min-width: 768px) {
        .publicationsList__title > h3 {
          float: left;
          max-width: calc(100% - 250px);
          font-size: 24px;
          line-height: 45px;
          letter-spacing: 1px;
          text-align: left;
          padding-right: 20px;
          margin-bottom: 0; } }
      @media only screen and (min-width: 1680px) {
        .publicationsList__title > h3 {
          font-size: 30px;
          line-height: 53px; } }
    .publicationsList__title > select {
      background: #FFFFFF;
      padding: 12px 52px 12px 20px;
      border-radius: 3px;
      border: none;
      font-size: 16px;
      line-height: 21px;
      font-weight: 600;
      width: 100%; }
      @media only screen and (min-width: 768px) {
        .publicationsList__title > select {
          float: right;
          width: auto;
          max-width: 250px; } }
      @media only screen and (min-width: 1680px) {
        .publicationsList__title > select {
          padding: 15px 52px 15px 20px;
          line-height: 22px; } }
    .publicationsList__title .select-chevron {
      position: absolute;
      right: 40px;
      bottom: 17px;
      font-size: 8px;
      line-height: 8px;
      color: #173C68;
      pointer-events: none; }
      @media only screen and (min-width: 768px) {
        .publicationsList__title .select-chevron {
          bottom: inherit;
          right: 20px;
          top: 20px; } }
      @media only screen and (min-width: 1680px) {
        .publicationsList__title .select-chevron {
          top: 24px; } }
  .publicationsList__articles {
    overflow: hidden;
    margin-bottom: 30px;
    padding: 0 20px; }
    @media only screen and (min-width: 768px) {
      .publicationsList__articles {
        padding: 0; } }
    @media only screen and (min-width: 1024px) {
      .publicationsList__articles {
        margin-bottom: 50px; } }
    .publicationsList__articles .flickity-viewport {
      overflow: visible; }
    .publicationsList__articles .article {
      position: relative;
      float: left;
      width: 80%;
      margin-right: 20px; }
      @media only screen and (min-width: 768px) {
        .publicationsList__articles .article--four {
          width: calc(25% - (30px / 4));
          margin-right: 10px; }
          .publicationsList__articles .article--four:nth-child(4n+4) {
            margin-right: 0; }
        .publicationsList__articles .article--three {
          width: calc(33.3334% - (20px / 3));
          margin-right: 10px; }
          .publicationsList__articles .article--three:nth-child(3n+3) {
            margin-right: 0; }
        .publicationsList__articles .article--two {
          width: calc(50% - (10px / 2));
          margin-right: 10px; }
          .publicationsList__articles .article--two:nth-child(2n+2) {
            margin-right: 0; } }
      .publicationsList__articles .article__image {
        display: block;
        height: 156px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover; }
        .publicationsList__articles .article__image:hover {
          opacity: 0.85; }
        @media only screen and (min-width: 1024px) {
          .publicationsList__articles .article__image {
            height: 175px; } }
        @media only screen and (min-width: 1680px) {
          .publicationsList__articles .article__image {
            height: 258px; } }
        .publicationsList__articles .article__image--noimage {
          background: #173C68 url("/img/site/noimage-icon.svg") no-repeat center center/75px; }
          @media only screen and (min-width: 1680px) {
            .publicationsList__articles .article__image--noimage {
              background-size: 100px; } }
      .publicationsList__articles .article__block {
        position: relative;
        padding: 25px 20px 20px;
        background: #FFFFFF; }
        @media only screen and (min-width: 1680px) {
          .publicationsList__articles .article__block {
            padding: 35px 30px 30px; } }
        .publicationsList__articles .article__block__title {
          font-size: 16px;
          line-height: 18px;
          margin: 0 0 10px;
          font-weight: normal;
          height: 36px;
          overflow: hidden; }
          .publicationsList__articles .article__block__title:hover {
            opacity: 0.85; }
          .publicationsList__articles .article__block__title a {
            color: #62666B; }
          @media only screen and (min-width: 1680px) {
            .publicationsList__articles .article__block__title {
              font-size: 16px;
              line-height: 22px;
              height: 44px; } }
        .publicationsList__articles .article__block__text {
          font-size: 14px;
          line-height: 18px;
          height: 54px;
          overflow: hidden;
          margin-bottom: 10px; }
          @media only screen and (min-width: 1024px) {
            .publicationsList__articles .article__block__text {
              margin-bottom: 15px; } }
          @media only screen and (min-width: 1680px) {
            .publicationsList__articles .article__block__text {
              font-size: 14px;
              line-height: 24px;
              height: 72px; } }
        .publicationsList__articles .article__block__link {
          font-size: 14px;
          line-height: 14px; }
          .publicationsList__articles .article__block__link:hover {
            opacity: 0.85; }
          @media only screen and (min-width: 1024px) {
            .publicationsList__articles .article__block__link {
              font-size: 16px;
              line-height: 16px; } }
        .publicationsList__articles .article__block__plus {
          position: absolute;
          right: 10px;
          top: -18px;
          height: 35px;
          width: 35px;
          background: #FF9100 url("/img/site/plus-icon.svg") no-repeat center center/15px;
          border-radius: 50%; }
          @media only screen and (min-width: 1024px) {
            .publicationsList__articles .article__block__plus {
              right: 20px; } }
          @media only screen and (min-width: 1680px) {
            .publicationsList__articles .article__block__plus {
              right: 25px;
              top: -25px;
              height: 56px;
              width: 56px;
              background-size: 18px; } }
      @media only screen and (min-width: 768px) {
        .publicationsList__articles .article--one {
          width: 100%; }
          .publicationsList__articles .article--one .article__image {
            height: 300px; } }
    @media only screen and (min-width: 768px) and (min-width: 768px) {
      .publicationsList__articles .article--one .article__image {
        height: 350px; } }
    @media only screen and (min-width: 768px) and (min-width: 1024px) {
      .publicationsList__articles .article--one .article__image {
        height: 400px; } }
    @media only screen and (min-width: 768px) and (min-width: 1680px) {
      .publicationsList__articles .article--one .article__image {
        height: 525px; } }
      @media only screen and (min-width: 768px) {
          .publicationsList__articles .article--one .article__block {
            max-width: 750px;
            margin: -90px auto 0; } }
  .publicationsList__more {
    padding: 0 20px;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .publicationsList__more {
        padding: 0; } }

.publicationsGrid .article {
  overflow: hidden;
  margin-bottom: 20px; }
  @media only screen and (min-width: 1024px) {
    .publicationsGrid .article {
      margin-bottom: 30px; } }
  .publicationsGrid .article__image {
    position: relative;
    height: 156px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    background-size: 100%;
    transition: background .3s ease-in-out; }
    .publicationsGrid .article__image:hover {
      background-size: 115%; }
    @media only screen and (min-width: 768px) {
      .publicationsGrid .article__image {
        float: left;
        width: 225px;
        height: 136px; } }
    @media only screen and (min-width: 1024px) {
      .publicationsGrid .article__image {
        height: 156px; } }
    .publicationsGrid .article__image__plus {
      position: absolute;
      right: 10px;
      bottom: -18px;
      height: 35px;
      width: 35px;
      background: #FF9100 url("/img/site/plus-icon.svg") no-repeat center center/15px;
      border-radius: 50%; }
      @media only screen and (min-width: 768px) {
        .publicationsGrid .article__image__plus {
          right: 20px;
          bottom: 20px; } }
    .publicationsGrid .article__image--noimage {
      background: #173C68 url("/img/site/noimage-icon.svg") no-repeat center center/75px; }
      @media only screen and (min-width: 1680px) {
        .publicationsGrid .article__image--noimage {
          background-size: 100px; } }
  .publicationsGrid .article__block {
    padding: 25px 20px 20px;
    background: #FFFFFF; }
    @media only screen and (min-width: 768px) {
      .publicationsGrid .article__block {
        padding: 20px;
        float: left;
        width: calc(100% - 225px);
        height: 136px; } }
    @media only screen and (min-width: 1024px) {
      .publicationsGrid .article__block {
        padding: 30px;
        height: 156px; } }
    .publicationsGrid .article__block__title {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 8px;
      display: block;
      color: #62666B; }
      @media only screen and (min-width: 768px) {
        .publicationsGrid .article__block__title {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 12px; } }
    .publicationsGrid .article__block__text {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 8px; }
      @media only screen and (min-width: 768px) {
        .publicationsGrid .article__block__text {
          font-size: 14px;
          line-height: 18px; } }

.publicationsGrid .event {
  overflow: hidden;
  margin-bottom: 20px; }
  @media only screen and (min-width: 1024px) {
    .publicationsGrid .event {
      margin-bottom: 30px; } }
  .publicationsGrid .event__image {
    position: relative;
    height: 100px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: block; }
    @media only screen and (min-width: 768px) {
      .publicationsGrid .event__image {
        float: left;
        width: 225px;
        height: 136px; } }
    @media only screen and (min-width: 1024px) {
      .publicationsGrid .event__image {
        height: 156px; } }
    .publicationsGrid .event__image__date {
      height: 100%;
      text-align: center;
      color: #fff;
      background: rgba(255, 255, 255, 0);
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.33) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(0, 0, 0, 0.33)));
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.33) 100%);
      background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.33) 100%);
      background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.33) 100%);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.33) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 ); }
      .publicationsGrid .event__image__date__info {
        position: relative;
        top: 46%;
        transform: translateY(-50%); }
        .publicationsGrid .event__image__date__info--day {
          font-size: 60px;
          font-weight: 700;
          line-height: 60px; }
        .publicationsGrid .event__image__date__info--month {
          font-size: 18px;
          font-weight: 600;
          line-height: 18px;
          text-transform: uppercase; }
    .publicationsGrid .event__image__plus {
      position: absolute;
      right: 10px;
      bottom: -18px;
      height: 35px;
      width: 35px;
      background: #FF9100 url("/img/site/plus-icon.svg") no-repeat center center/15px;
      border-radius: 50%; }
      @media only screen and (min-width: 768px) {
        .publicationsGrid .event__image__plus {
          right: 20px;
          bottom: 20px; } }
    .publicationsGrid .event__image--noimage {
      background-color: #173C68; }
  .publicationsGrid .event__block {
    padding: 20px;
    background: #FFFFFF; }
    @media only screen and (min-width: 768px) {
      .publicationsGrid .event__block {
        float: left;
        width: calc(100% - 225px);
        height: 136px; } }
    @media only screen and (min-width: 1024px) {
      .publicationsGrid .event__block {
        height: 156px; } }
    .publicationsGrid .event__block__title {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 8px;
      display: block;
      color: #62666B; }
      @media only screen and (min-width: 768px) {
        .publicationsGrid .event__block__title {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 12px; } }
    .publicationsGrid .event__block__text {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 8px; }
      @media only screen and (min-width: 768px) {
        .publicationsGrid .event__block__text {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 12px; } }
    .publicationsGrid .event__block__date {
      font-size: 10px;
      line-height: 12px; }
      @media only screen and (min-width: 768px) {
        .publicationsGrid .event__block__date {
          font-size: 12px;
          line-height: 14px; } }

#map {
  width: 100%;
  height: 320px; }
  @media only screen and (min-width: 1024px) {
    #map {
      height: 350px; } }
  @media only screen and (min-width: 1680px) {
    #map {
      height: 400px; } }

.results {
  padding: 20px;
  margin: 0 auto;
  max-width: 960px; }
  @media only screen and (min-width: 1024px) {
    .results {
      padding: 30px; } }
  @media only screen and (min-width: 1680px) {
    .results {
      padding: 50px 30px; } }
  .results__title {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 20px;
    text-align: center;
    font-weight: normal; }
    @media only screen and (min-width: 768px) {
      .results__title {
        font-size: 24px;
        line-height: 28px; } }
    @media only screen and (min-width: 1024px) {
      .results__title {
        font-size: 32px;
        line-height: 38px;
        margin: 0 0 30px; } }
    @media only screen and (min-width: 1680px) {
      .results__title {
        font-size: 40px;
        line-height: 55px;
        margin: 0 0 40px; } }
    .results__title > span {
      color: #FF9100; }
  .results__pagination {
    text-align: center;
    font-size: 16px;
    line-height: 22px; }
    .results__pagination__prev {
      margin: 0 10px;
      color: #62666B; }
      .results__pagination__prev:hover {
        opacity: 0.85; }
    .results__pagination__next {
      margin: 0 10px;
      color: #62666B; }
      .results__pagination__next:hover {
        opacity: 0.85; }
    .results__pagination__page {
      margin: 0 8px;
      color: #62666B;
      display: none; }
      .results__pagination__page:hover {
        opacity: 0.85; }
      @media only screen and (min-width: 768px) {
        .results__pagination__page {
          display: inline-block; } }
      .results__pagination__page--active {
        color: #FF9100; }

.cta {
  background: #fff !important;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px; }
  @media only screen and (min-width: 1024px) {
    .cta {
      padding: 30px;
      margin-bottom: 30px; } }
  .cta .button {
    display: inline-block;
    width: fit-content;
    padding: 10px 30px;
    border-radius: 20px;
    font-weight: 300;
    font-size: 14px;
    text-transform: none; }
  .cta__title {
    color: #1D1D1B;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    margin: 0 0 15px;
    letter-spacing: .3px; }
    @media only screen and (min-width: 768px) {
      .cta__title {
        font-size: 24px;
        line-height: 32px; } }
    @media only screen and (min-width: 1024px) {
      .cta__title {
        font-size: 28px;
        line-height: 32px; } }
  .cta__text {
    color: #1D1D1B;
    font-size: 14px;
    line-height: 150%;
    font-weight: 300;
    margin-bottom: 20px; }
    @media only screen and (min-width: 768px) {
      .cta__text {
        font-size: 16px; } }

.file {
  position: relative;
  display: block;
  background-color: #EAEEF1;
  padding: 20px 20px 20px 85px;
  margin-bottom: 20px;
  line-height: 18px; }
  @media only screen and (min-width: 768px) {
    .file {
      line-height: 24px; } }
  @media only screen and (min-width: 1024px) {
    .file {
      margin-bottom: 40px;
      padding: 25px 25px 25px 95px;
      line-height: 34px; } }
  @media only screen and (min-width: 1680px) {
    .file {
      margin-bottom: 50px; } }
  .file:hover {
    opacity: 0.85; }
  .file::after {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e924";
    font-size: 40px;
    color: #FF9100; }
    @media only screen and (min-width: 1024px) {
      .file::after {
        font-size: 50px; } }
  .file__title {
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    color: #62666B; }
    .file__title:hover {
      opacity: 0.85; }
    @media only screen and (min-width: 768px) {
      .file__title {
        font-size: 16px;
        line-height: 24px; } }
    @media only screen and (min-width: 1024px) {
      .file__title {
        font-size: 20px;
        line-height: 34px; } }
  .file__text {
    font-size: 12px;
    line-height: 24px;
    font-weight: 300;
    font-style: italic;
    color: #62666B; }
    @media only screen and (min-width: 768px) {
      .file__text {
        font-size: 14px;
        line-height: 24px; } }
    @media only screen and (min-width: 1024px) {
      .file__text {
        font-size: 16px;
        line-height: 34px; } }

.video {
  margin-bottom: 20px; }
  @media only screen and (min-width: 1024px) {
    .video {
      margin-bottom: 40px; } }
  @media only screen and (min-width: 1680px) {
    .video {
      margin-bottom: 50px; } }
  .video__wrapper {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0; }
    .video__wrapper iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .video__caption {
    margin-top: 10px;
    margin-bottom: -12px;
    padding-right: 10px;
    font-size: 10px;
    line-height: 12px;
    text-align: right; }
    @media only screen and (min-width: 768px) {
      .video__caption {
        padding-right: 0; } }

.gallery {
  overflow: hidden;
  margin-bottom: 20px; }
  @media only screen and (min-width: 768px) {
    .gallery {
      margin-bottom: 0; } }
  @media only screen and (min-width: 1024px) {
    .gallery {
      margin-bottom: 20px; } }
  .gallery a {
    width: 100%;
    float: left;
    height: 320px;
    background-position: center center;
    background-size: cover;
    display: block;
    position: relative; }
    @media only screen and (min-width: 768px) {
      .gallery a {
        width: calc(33.3334% - (40px / 3));
        margin-right: 20px;
        margin-bottom: 20px;
        height: 225px; }
        .gallery a:nth-child(3n+3) {
          margin-right: 0; }
        .gallery a:hover .hover {
          opacity: 1; } }
    @media only screen and (min-width: 1024px) {
      .gallery a {
        position: relative;
        width: calc(33.3334% - 20px);
        margin-right: 30px;
        margin-bottom: 30px; } }
    @media only screen and (min-width: 1680px) {
      .gallery a {
        height: 300px; } }
    .gallery a .hover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: opacity ease-out .2s; }
      .gallery a .hover::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60px;
        height: 60px;
        border-radius: 60px;
        background: #FF9100 url("/img/site/eye-icon.svg") no-repeat center center/30px; }
  .gallery.with-comment a.carousel-cell {
    height: auto; }
    @media only screen and (min-width: 1680px) {
      .gallery.with-comment a.carousel-cell {
        height: auto; } }
    .gallery.with-comment a.carousel-cell img {
      height: 320px;
      max-width: 100%; }
      @media only screen and (min-width: 768px) {
        .gallery.with-comment a.carousel-cell img {
          height: 225px; } }
      @media only screen and (min-width: 1680px) {
        .gallery.with-comment a.carousel-cell img {
          height: 300px; } }
    .gallery.with-comment a.carousel-cell p {
      font-size: 16px;
      color: #1D1D1B;
      margin: 10px 0 30px; }

.flickity-prev-next-button.previous {
  width: 50px;
  height: 50px;
  padding: 0;
  background: #FF9100 url("/img/site/chevron-left.svg") no-repeat center right 10px/12px;
  border-radius: 40px;
  left: -25px;
  transition: opacity ease-out .2s; }
  .flickity-prev-next-button.previous:disabled {
    opacity: .3; }
  .flickity-prev-next-button.previous svg {
    display: none; }

.flickity-prev-next-button.next {
  width: 50px;
  height: 50px;
  padding: 0;
  background: #FF9100 url("/img/site/chevron-right.svg") no-repeat center left 10px/12px;
  border-radius: 40px;
  right: -25px;
  transition: opacity ease-out .2s; }
  .flickity-prev-next-button.next:disabled {
    opacity: .3; }
  .flickity-prev-next-button.next svg {
    display: none; }

.article_form {
  overflow: hidden;
  margin-bottom: 50px; }
  .article_form__left {
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .article_form__left {
        float: left;
        width: calc(50% - 10px);
        margin-right: 20px; } }
    @media only screen and (min-width: 1024px) {
      .article_form__left {
        width: calc(50% - 20px);
        margin-right: 40px; } }
  .article_form__right {
    width: 100%;
    padding: 0 20px; }
    @media only screen and (min-width: 768px) {
      .article_form__right {
        float: left;
        width: calc(50% - 10px);
        padding: 0; } }
    @media only screen and (min-width: 1024px) {
      .article_form__right {
        width: calc(50% - 20px); } }

.contact {
  padding: 0 20px; }
  @media only screen and (min-width: 768px) {
    .contact {
      padding: 0; } }
  .contact__title {
    margin: 0 0 20px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600; }
    @media only screen and (min-width: 768px) {
      .contact__title {
        font-size: 20px;
        line-height: 28px; } }
    @media only screen and (min-width: 1680px) {
      .contact__title {
        font-size: 32px;
        line-height: 36px; } }
  .contact__addresses {
    display: flex;
    flex-wrap: wrap; }
  .contact__address {
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #EAEEF1; }
    @media only screen and (min-width: 768px) {
      .contact__address {
        width: calc(50% - 10px);
        margin-right: 20px; }
        .contact__address:nth-child(2n+2) {
          margin-right: 0; }
        .contact__address__single {
          width: 100%;
          margin-right: 0; } }
    .contact__address__description {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 15px; }
      @media only screen and (min-width: 768px) {
        .contact__address__description {
          font-size: 18px;
          line-height: 22px; } }
      @media only screen and (min-width: 1680px) {
        .contact__address__description {
          font-size: 22px;
          line-height: 24px; } }
    .contact__address__infos {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      margin-bottom: 15px; }
      .contact__address__infos a {
        text-decoration: underline; }
      @media only screen and (min-width: 768px) {
        .contact__address__infos {
          font-size: 16px;
          line-height: 24px; } }
      @media only screen and (min-width: 1680px) {
        .contact__address__infos {
          font-size: 20px;
          line-height: 30px; } }
  .contact__method {
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    margin-bottom: 15px; }
    @media only screen and (min-width: 768px) {
      .contact__method {
        font-size: 16px;
        line-height: 20px; } }
    @media only screen and (min-width: 1680px) {
      .contact__method {
        font-size: 18px;
        line-height: 24px; } }
    .contact__method a {
      color: #62666B; }
    .contact__method strong {
      font-weight: 400; }
    .contact__method__title {
      margin-bottom: 10px; }
    .contact__method__email, .contact__method__phone {
      margin-bottom: 10px; }
      .contact__method__email span, .contact__method__phone span {
        font-size: 12px; }
        @media only screen and (min-width: 1680px) {
          .contact__method__email span, .contact__method__phone span {
            font-size: 14px; } }
    .contact__method__email a {
      text-decoration: underline; }
