.results {

  padding: 20px;
  margin: 0 auto;
  max-width: 960px;

  @media only screen and (min-width: $mediumBreakpoint) {

    padding: 30px;
  }

  @media only screen and (min-width: $largeBreakpoint) {

    padding: 50px 30px;
  }

  &__title {

    font-size: 20px;
    line-height: 24px;
    margin: 0 0 20px;
    text-align: center;
    font-weight: normal;

    @media only screen and (min-width: $smallBreakpoint) {

      font-size: 24px;
      line-height: 28px;
    }

    @media only screen and (min-width: $mediumBreakpoint) {
      font-size: 32px;
      line-height: 38px;
      margin: 0 0 30px;
    }

    @media only screen and (min-width: $largeBreakpoint) {

      font-size: 40px;
      line-height: 55px;
      margin: 0 0 40px;
    }

    & > span {

      color: $defaultColorEnd;
    }
  }

  &__pagination {

    text-align: center;
    font-size: 16px;
    line-height: 22px;

    &__prev {

      margin: 0 10px;
      color: $defaultColorText;

      &:hover {
        opacity: $hoverOpacity;
      }
    }

    &__next {

      margin: 0 10px;
      color: $defaultColorText;

      &:hover {
        opacity: $hoverOpacity;
      }
    }

    &__page {

      margin: 0 8px;
      color: $defaultColorText;
      display: none;

      &:hover {
        opacity: $hoverOpacity;
      }

      @media only screen and (min-width: $smallBreakpoint) {

        display: inline-block;
      }

      &--active {

        color: $defaultColorEnd;
      }
    }
  }
}
